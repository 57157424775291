import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as moment from "moment";
import {plainToInstance} from "class-transformer";
import {firstValueFrom, Observable} from "rxjs";
import {BaseRestAPIService} from "@core/services/base-rest-api.service";
import {SecurityService} from "@core/services/security.service";
import {environment} from "../../../environments/environment";

moment.locale('nl');

// TODO: Fix typing
@Injectable({
  providedIn: 'root'
})
export class CompanyLocationService extends BaseRestAPIService<any, any, any> {
  get activeLocation(): any {
    return this._activeLocation;
  }

  set activeLocation(value: any) {
    this._activeLocation = value;
  }
  private _activeLocation: any;

  // FIXME: temp solution to get rijges grouped, fix this in the backend!
  get examinationTypes() {
    return this._activeLocation.availableExaminationTypes.filter(e => e.name !== 'rijges-095').map(e => {
      if(e.name === 'rijges-107') {
        e.prettyName = 'Rijbewijs oogkeuring'
      }
      return e;
    });
  }

  constructor(http: HttpClient,
              securityService: SecurityService) {
    super(http,
      `${environment.apiUrl}/public/locations`,
      input => plainToInstance(CompanyLocationService,
        input
      ), securityService);
  }
}
