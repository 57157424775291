<form
  *ngIf="currentManager?.formGroup"
  class="form flex flex-col"
  [formGroup]="currentManager.formGroup"
  (ngSubmit)="save()"
  autocomplete="off"
>
  <div class="section" *ngFor="let section of sections">
    <h2 class="text-xl font-bold">{{ section.label }}</h2>

    <div class="flex flex-wrap" *ngFor="let field of section.fields">
      <div class="flex gap-4" *ngIf="field.children else singleInput">
        <app-form-inputs
          *ngFor="let sub of field.children"
          [ngClass]="getColumns(field.children.length)"
          [input]="sub"
          [formGroup]="currentManager.formGroup"
        ></app-form-inputs>
      </div>

      <ng-template #singleInput>
        <app-form-inputs
          [input]="field"
          [formGroup]="currentManager.formGroup"
        ></app-form-inputs>
      </ng-template>
    </div>
  </div>


  <div class="flex gap-4">
    <app-button
      [type]="'submit'"
    >
      Opslaan
    </app-button>

    <app-form-dev-tools
      [form]="currentManager.formGroup"
    ></app-form-dev-tools>
  </div>
</form>
