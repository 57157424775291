import { ValidatorFn, AbstractControl } from '@angular/forms';

export function minArrayLength(min: number): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if (!control.value || control.value.length < min) {
      return { 'minArrayLength': { value: control.value, requiredLength: min } };
    }
    return null;
  };
}
