<div class="topbar">
  <div class="container mx-auto py-8 text-white">
      <div class="flex text-body">
          <img class="cursor-pointer mx-auto lg:mx-0" [routerLink]="'/'" src="assets/okulus-logo-db.svg">

          <div class="hidden lg:flex mx-auto" *ngIf="!isMakingAppointment">
              <ul class="flex gap-6">
                  <li
                    *ngFor="let link of links"
                    [routerLink]="link.url"
                    routerLinkActive="active"
                    class="px-2 py-3 cursor-pointer"
                  >
                      {{ link.label }}
                  </li>
              </ul>
          </div>

          <a class="ml-auto px-2 py-3" href="#">
              <span>
                Uitloggen
              </span>
          </a>
      </div>

    <div class="steps flex mt-8 justify-center lg:justify-normal" *ngIf="isMakingAppointment">
        <div *ngFor="let step of steps; let i = index; let last = last" class="step flex gap-2 items-center">
            <div
                class="step__number ml-2"
                [class.step__number--active]="i === currentStep"
                [class.step__number--done]="i < currentStep"
                [ngClass]="{'hidden lg:flex': i > currentStep || i < currentStep}"
            >
                <div *ngIf="i < currentStep else stepNumber">
                    <fa-icon [icon]="['far', 'check']"></fa-icon>
                </div>
                <ng-template #stepNumber>
                    {{i + 1}}
                </ng-template>
            </div>
            <div class="flex items-center gap-2" [ngClass]="{'hidden lg:flex': i > currentStep || i < currentStep}">
                <div class="step-text">{{step.name}}</div>
                <mat-divider
                        *ngIf="!last"
                        class="step__divider hidden lg:flex"
                        [class.step__divider--active]="i === currentStep"
                />
            </div>
        </div>
    </div>
  </div>
</div>
