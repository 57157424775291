<app-base-dialog
  [title]="title"
>
<!--  <app-dynamic-form></app-dynamic-form>-->

  <div class="dialog__buttons" dialog-actions>
    <app-button>
      Annuleren
    </app-button>
    <app-button>
      Opslaan
    </app-button>
  </div>

</app-base-dialog>
