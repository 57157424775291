<div class="flex flex-wrap flex-col-reverse lg:flex-row items-center justify-between mt-6">
    <div (click)="displayPreviousStep()" class="card__stepBack mt-4 lg:mt-0">
        <fa-icon class="mr-2" [icon]="['far', 'arrow-left-long']"></fa-icon>
        Ga terug
    </div>

    <app-button
            (onClick)="displayNextStep()"
            [disabled]="!canProceed"
    >
        <ng-container *ngIf="proceedText else nextStepTitle">
            {{proceedText}}
        </ng-container>
        <ng-template #nextStepTitle>
            {{this.stepList[this.stepService.currentStepIndex + 1]?.inputs?.title}}
        </ng-template>
        <fa-icon class="ml-2" [icon]="['far', 'arrow-right']"></fa-icon>
    </app-button>
</div>
