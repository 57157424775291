import {Injectable, Type} from "@angular/core";
import {AppointmentService} from "@core/services/appointment.service";
import {
    BaseQuestionnaireTypeComponent
} from "../../pages/questionnaires/types/base-questionnaire-type/base-questionnaire-type.component";
import {
    GlaucomaQuestionnaireComponent
} from "../../pages/questionnaires/types/glaucoma-questionnaire/glaucoma-questionnaire.component";
import {ExamType} from "@util/types/models";
import {
    Rijges107QuestionnaireComponent
} from "../../pages/questionnaires/types/rijges107-questionnaire/rijges107-questionnaire.component";
import {
    Rijges095QuestionnaireComponent
} from "../../pages/questionnaires/types/rijges095-questionnaire/rijges095-questionnaire.component";
import {
    OptometricExaminationQuestionnaireComponent
} from "../../pages/questionnaires/types/optometric-examination-questionnaire/optometric-examination-questionnaire.component";

export interface Questionnaire {
    component: Type<BaseQuestionnaireTypeComponent>,
    examType: ExamType
    inputs: { // Required variables for the component
        title: string,
        nextStepFunction?: () => void
    }
}

@Injectable({
    providedIn: 'root'
})
export class QuestionnaireService {
    constructor() {
    }

    public getQuestionnaires(): Questionnaire[] {
        return [
            {
                component: GlaucomaQuestionnaireComponent,
                examType: ExamType.GlaucomaScreening,
                inputs: {
                    title: 'Glaucoomscreening',
                }
            },
            {
                component: Rijges107QuestionnaireComponent,
                examType: ExamType.Rijges107,
                inputs: {
                    title: 'Rijbewijskeuring 107',
                }
            },
            {
                component: Rijges095QuestionnaireComponent,
                examType: ExamType.Rijges095,
                inputs: {
                    title: 'Rijbewijskeuring 095',
                }
            },
            {
                component: OptometricExaminationQuestionnaireComponent,
                examType: ExamType.OptometricExamination,
                inputs: {
                    title: 'Optometrisch onderzoek',
                }
            }
        ];
    }
}
