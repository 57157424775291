import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuestionnairesComponent } from './questionnaires.component';
import {BasePageModule} from "../base-page/base-page.module";
import {FormInputsModule} from "@components/molecules/forms/form-inputs/form-inputs.module";
import {BaseQuestionnaireTypeModule} from "./types/base-questionnaire-type/base-questionnaire-type.module";



@NgModule({
  declarations: [
    QuestionnairesComponent
  ],
    imports: [
        CommonModule,
        BasePageModule,
        FormInputsModule,
        BaseQuestionnaireTypeModule
    ]
})
export class QuestionnairesModule { }
