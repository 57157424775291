import {Component, OnInit} from "@angular/core";
import {Patient, PatientService} from "@core/services/patient.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
    selector: 'app-patient-information',
    templateUrl: './patient-information.component.html',
    styleUrls: ['./patient-information.component.scss']
})
export class PatientInformationComponent implements OnInit{
    // FIXME:: This will differ per db-seed, please set the correct ID from the first patient before demonstrating.
    uid: string = '48eecde2-98d8-47b8-9dd5-e398d56933a4';

    patient: Patient;

    constructor(
        private patientService: PatientService,
        private matSnackBar: MatSnackBar,
    ) {
    }

    ngOnInit(): void {
        this.patientService.get(this.uid).subscribe(patient => {
            this.patient = patient;
        });
    }

    /**
     * FIXME:: Call an edit event or something to toggle to edit mode.
     */
    onEdit(): void {
        // FIXME:: Not implemented for this demo, just show that in a snackbar.
        this.matSnackBar.open('Bewerken is nog niet geïmplementeerd.', 'Sluiten', {duration: 5000});
    }
}
