<!--FIXME:: It could be that this whole component needs to be divided into smaller components -->
<div class="h-full flex items-center">
    <div class="max-w-md mx-auto text-center bg-white px-4 sm:px-8 py-10 rounded-xl">
        <header class="mb-4">
            <h1 class="text-2xl font-bold mb-1">Vul uw verificatie code in</h1>
            <p class="text-[15px] text-grey-50">Er is een zes-cijferige code verstuurd via SMS naar <span class="font-semibold">+316****123</span>. Voer deze alstublieft in.</p>
        </header>

        <!-- FIXME:: Maybe use the app-form from our own components here? -->
        <form id="otp-authentication">
            <div class="flex items-center justify-center gap-3">
                <!-- FIXME:: Combine this logic within the form-input component maybe?? -->
                <input
                        #otpInput
                        *ngFor="let i of validCode"
                        type="text"
                        class="w-14 h-14 text-center text-2xl font-extrabold text-grey-90 bg-grey-5 border border-grey-5 hover:border-grey-5 appearance-none rounded p-4 outline-none focus:bg-white focus:border-blue-40 focus:ring-2 focus:ring-blue-10"
                        pattern="\d*" maxlength="1" />
            </div>

            <!-- Fixme:: Maybe we need to Oculize (Oculus style) this component some more. -->
            <div class="max-w-[260px] mx-auto mt-4">
                <button
                        (click)="onSubmit(); $event.preventDefault()"
                        type="submit"
                        class="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-blue-50 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-indigo-600 focus:outline-none focus:ring focus:ring-indigo-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 transition-colors duration-150">
                    Bevestigen
                </button>
            </div>
        </form>
        <div class="text-sm text-grey-50 mt-4">
            Geen code ontvangen? <span (click)="onResend()" class="font-medium text-blue-50 hover:text-blue-60 cursor-pointer">Opnieuw versturen</span>
        </div>
    </div>
</div>
