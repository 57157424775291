import { Component } from '@angular/core';
import {BaseQuestionnaireTypeComponent, Section} from "../base-questionnaire-type/base-questionnaire-type.component";
import {QuestionnaireService} from "@core/services/questionnaire.service";
import {FormBuilder} from "@angular/forms";
import {BaseFormInput, FormInputType, SingleRadioInput} from "@util/types/interfaces";
import {
  additionalInformation,
  optometricExaminationCorrectionQuestions, optometricExaminationEyeHealthQuestions,
  optometricExaminationGeneralHealthQuestions,
  SectionTitles
} from "../sharedQuestions";
import {YesNoDropdown} from "@util/types/dropdowns";

@Component({
  selector: 'app-optometric-examination-questionnaire',
  templateUrl: '../base-questionnaire-type/base-questionnaire-type.component.html',
  styleUrls: ['./optometric-examination-questionnaire.component.scss']
})
export class OptometricExaminationQuestionnaireComponent extends BaseQuestionnaireTypeComponent {
  constructor(
      override questionnairesService: QuestionnaireService,
      override formBuilder: FormBuilder
  ) {
    const sections: Section[] = [
      {
        title: SectionTitles.Complaint,
        inputs: [
          new BaseFormInput('Kun je een toelichting geven over jouw oogklacht(en)?', 'explanation', FormInputType.Textarea),
        ]
      },
      {
        title: SectionTitles.GeneralHealth,
        inputs: optometricExaminationGeneralHealthQuestions
      },
      {
        title: SectionTitles.EyeCorrection,
        inputs: optometricExaminationCorrectionQuestions
      },
      {
        title: SectionTitles.EyeHealth,
        inputs: optometricExaminationEyeHealthQuestions
      },
      {
        title: SectionTitles.Other,
        inputs: [additionalInformation]
      }
    ];

    super(questionnairesService, formBuilder, sections);
  }
}
