import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DashboardModule} from "./dashboard/dashboard.module";
import {CreateAppointmentModule} from "./create-appointment/create-appointment.module";
import {MedicalInformationModule} from "./medical-information/medical-information.module";
import {DialogsModule} from "@components/organisms/dialogs/dialogs.module";
import {QuestionnairesModule} from "./questionnaires/questionnaires.module";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    DashboardModule,
    CreateAppointmentModule,
    MedicalInformationModule,
    DialogsModule,
    QuestionnairesModule
  ]
})
export class PagesModule { }
