import { Injectable } from "@angular/core";
import {BaseRestAPIService} from "@core/services/base-rest-api.service";
import {HttpClient} from "@angular/common/http";
import {SecurityService} from "@core/services/security.service";
import {environment} from "../../../environments/environment";
import {plainToInstance} from "class-transformer";

// FIXME:: This is a mock due to the fact I needed a fast type to work with, copying-pasting and front-end model generation
// FIXME:: were giving a lot of dependency errors regarding autoImplement and missing types.
export class Patient {
    id: string;
    number: string;
    email: string;
    phoneNumber: string;
    phoneNumber2?: string;
    phoneNumber3?: string;
    initials: string;
    firstName: string;
    middleName?: string;
    lastName: string;
    partnerMiddleName?: string;
    partnerLastName?: string;
    bsn: string;
    bsnOrigin?: string;
    dateOfBirth: Date;
    gender: string;
    address: {
        postalCode: string;
        houseNr: number;
        houseNrSuffix?: string;
        city: string;
        streetName: string;
        country: string;
        municipality: string;
    };
    externalStorageId?: string;
    externalStorageType?: string;
    idType?: string;
    idNumber?: number;
    companies: [
        {
            id: string;
        }
    ];
    caregivers: [
        {
            id: string;
        }
    ];
    remarks?: string;
    externalCommunications: string[];
    referrals: string[]
}

@Injectable({
    providedIn: 'root'
})
export class PatientService extends BaseRestAPIService<Patient, any, any>{
    constructor(http: HttpClient,
                securityService: SecurityService) {
        super(http,
            `${environment.apiUrl}/patient`,
            input => plainToInstance(Patient,
                input
            ), securityService);
        }
}
