<div class="max-w-5xl mx-auto my-10 flex items-center justify-center">
    <div class="w-2/5 border-r-blue-30 bg-white rounded-xl shadow-md">
        <div class="flex flex-col p-4 space-y-10">
            <div class="section">
                <h2 class="text-xl ml-1">Patiëntgegevens</h2>
                <div class="flex">
                    <div class="w-full p-6 bg-grey-5 rounded-lg flex justify-between">
                        <div class="label-group w-1/2 flex flex-col font-bold">
                            <span>Patientnummer</span>
                            <span>E-mail</span>
                            <span>Telefoonnummer</span>
                            <span>BSN-Nummer</span>
                            <span>Initialen</span>
                            <span>Voornaam</span>
                            <span>Achternaam</span>
                            <span>Geslacht</span>
                            <span>Geboortedatum</span>
                        </div>
                        <div *ngIf="patient" class="value-group w-1/2 flex flex-col font-medium text-grey-80">
                            <span>{{ patient.number }}</span>
                            <span>{{ patient.email }}</span>
                            <span>{{ patient.phoneNumber }}</span>
                            <span>{{ patient.bsn }}</span>
                            <span>{{ patient.initials }}</span>
                            <span>{{ patient.firstName }}</span>
                            <span>{{ patient.lastName }}</span>
                            <span>{{ patient.gender == 'female' ? 'Vrouwelijk' : 'Mannelijk' }}</span>
                            <span>{{ patient.dateOfBirth | amDateFormat:'DD-MM-YYYY' }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section">
                <h2 class="text-xl ml-1">Adresgegevens</h2>
                <div class="flex">
                    <div class="w-full p-6 bg-grey-5 rounded-lg flex justify-between">
                        <div class="label-group w-1/2 flex flex-col font-bold">
                            <span>Straat</span>
                            <span>Huisnummer</span>
                            <span>Postcode</span>
                            <span>Stad</span>
                            <span>Land</span>
                            <span>Gemeente</span>
                        </div>
                        <div *ngIf="patient" class="value-group w-1/2 flex flex-col font-medium text-grey-80">
                            <span>{{ patient.address.streetName }}</span>
                            <span>{{ patient.address.houseNr }}</span>
                            <span>{{ patient.address.postalCode }}</span>
                            <span>{{ patient.address.city }}</span>
                            <span>{{ patient.address.country }}</span>
                            <span>{{ patient.address.municipality }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <button
                    class="bg-blue-50 hover:bg-blue-80 text-white font-semibold rounded-full py-2 text-sm"
                    (click)="onEdit()">
                Gegevens bijwerken
            </button>
        </div>
    </div>
</div>
