import { Injectable } from '@angular/core';
import {BaseRestAPIService} from "@core/services/base-rest-api.service";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {SecurityService} from "@core/services/security.service";
import {plainToInstance} from "class-transformer";
import {CreateFormEntry, FormEntry, UpdateFormEntry} from "@util/types/models";

@Injectable({
  providedIn: 'root'
})
export class FormEntryService extends BaseRestAPIService<FormEntry, CreateFormEntry, UpdateFormEntry> {

  constructor(http: HttpClient,
              securityService: SecurityService) {
    super(http,
        `${environment.apiUrl}/form-entry`,
        input => plainToInstance(FormEntry,
            input
        ), securityService);
  }
}
