import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {DefaultPagination, IPaginationMeta, TableActionEvent, TableColumn} from "@util/types/interfaces";
import {Sort} from "@angular/material/sort";
import {DateTimeNotations, enumValueToName, TableActionType} from '@util/types/enums';
import * as moment from "moment";
import {parseName} from "@util/helpers/name-helper";

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit {
  get rowIndex() {
    return this._rowIndex;
  }

  set rowIndex(value) {
    this._rowIndex = value;
    this.selectRow(this.dataSource.data[value]);
    this.hasNext = this._rowIndex < this.dataSource.data.length - 1;
    this.hasPrevious = this._rowIndex > 0;
  }
  @Input() dataSource: MatTableDataSource<any>;
  @Input() tableColumns: TableColumn[];
  @Input() noDataText: string;
  @Input() showSelectedRow = true;
  @Input() unselectAfterClick = false; // Unselects the row after clicking, for example when you want to show a dialog on click.
  @Input() paginationMeta: IPaginationMeta = DefaultPagination;
  @Output() paginationChange: EventEmitter<any> = new EventEmitter();
  @Output() selectRowEvent: EventEmitter<any> = new EventEmitter();
  @Output() actionEvent: EventEmitter<TableActionEvent> = new EventEmitter();
  private _selectedSort: Sort = {active: 'id', direction: 'asc'};
  public selectedRow = null;
  tableActions = TableActionType;

  @Input() set selectedSort(sort: Sort) {
    if (sort) {
      this._selectedSort = sort;
    }
  }
  get selectedSort(): Sort {
    return this._selectedSort;
  }
  @Output() sortChange: EventEmitter<any> = new EventEmitter();
  private _rowIndex;
  public displayedColumns: string[];
  public hasNext = false;
  public hasPrevious = false;

  constructor() {}

  ngOnInit(): void {
    this.displayedColumns = this.tableColumns.map(column => column.key);
  }

  selectRow(row): void {
    this.selectedRow = this.selectedRow === row ? null : row;
    this.selectRowEvent.emit(row);

    if (this.unselectAfterClick) {
      this.selectedRow = null;
    }
  }

  getClasses(column, value) {
    return column.classes(column, value);
  }

  parseData(value: string, parseObj, element?: any): any {
    if (parseObj.isDate) {
      const notation = parseObj.dateFormat ? parseObj.dateFormat : DateTimeNotations.DefaultDateNotation;
      return value ? moment(value).format(notation) : 'Geen datum bekend';
    } else if(parseObj.isName) {
      return parseName((element) as any);
    } else if(parseObj.isNameValue) {
      return parseName((value) as any);
    } else if(parseObj.isFullName) {
      return parseName((element) as any, true);
    } else if (parseObj.isDoB) {
      const notation = parseObj.dateFormat ? parseObj.dateFormat : DateTimeNotations.DefaultDateNotation;
      const dob = moment(value);
      return value ? `${dob.format(notation)} (${moment().diff(dob, 'years', false)})` : 'Geen geboortedatum bekend';
    } else if(parseObj.func) {
      return parseObj.func(value);
    } else {
      if(!value && typeof value !== 'boolean') {
        return 'Onbekend';
      }

      if(typeof value === 'string' || typeof value === "boolean") {
        return enumValueToName(parseObj.array, value);
      } else {
        return (value as any).map(v => enumValueToName(parseObj.array, v)).join(', ');
      }
    }
  }

  resolveTableData(path, element): any {
    if(path === '.') { // to support returning "root" paths (fex: when the parent/root object is the data we need).
      return element;
    }

    return path.split('.').reduce((prev, curr) => {
      return prev ? prev[curr] : null;
    }, element || self);
  }

  sortData(sort: Sort): void {
    if(sort.direction === '') {
      sort.direction = 'asc'
    }

    this.sortChange.emit(sort);
    this.selectedSort = sort;
  }
}
