<!--  formErrorContainer-->
<ng-container
  formErrorContainer
  [questionKey]="input.key"
  [formGroup]="input.formGroupName ? $any(formGroup.get(input.formGroupName)) : formGroup"
>
  <mat-form-field class="formField errorWrapper w-full" formErrorContainer>
    <input
        [name]="input.key"
        matInput
        [formControlName]="input.key"
        autocomplete="off"
        [disabled]="input.disabled"
        [placeholder]="input.placeholder ? input.placeholder : ''"
    >
<!--    <fa-icon-->
<!--      *ngIf="isDisabled()"-->
<!--      [icon]="['far', 'lock-keyhole']"-->
<!--      class="disabledIcon"-->
<!--    ></fa-icon>-->
  </mat-form-field>
</ng-container>
