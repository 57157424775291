<div
    class="item text-sm py-2 px-5 flex items-center lg:py-6 lg:px-10 lg:text-xl"
    [class.item--selected]="treatment.name === selectedTreatment?.name"
    (click)="selectTreatment.emit(treatment)"
>
    <div
        class="checkbox mr-4"
        [class.checkbox--selected]="treatment.name === selectedTreatment?.name"
    ></div>

    <span>{{treatment.prettyName}}</span>
    <span class="ml-auto">€ {{treatment.price}},-</span>
</div>
