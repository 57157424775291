import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {BaseButtonModule} from "@components/atoms/buttons/base-button/base-button.module";
import {SidebarModule} from "@components/templates/sidebar/sidebar.module";
import {TopbarModule} from "@components/templates/topbar/topbar.module";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {FontAwesomeLibraryModule} from "@util/modules/font-awesome-library/font-awesome-library.module";
import {PagesModule} from "./pages/pages.module";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";
import {HttpClientModule} from "@angular/common/http";
import {SecurityService} from "@core/services/security.service";
import {BaseDialogModule} from "@components/organisms/dialogs/base-dialog/base-dialog.module";
import {MAT_DIALOG_DEFAULT_OPTIONS} from "@angular/material/dialog";
import {MAT_MENU_DEFAULT_OPTIONS} from "@angular/material/menu";
import {DialogsModule} from "@components/organisms/dialogs/dialogs.module";
import {HotToastModule, provideHotToastConfig} from '@ngneat/hot-toast';
import {BaseLayoutModule} from "./layouts/base-layout/base-layout.module";
import { InputMaskModule } from '@ngneat/input-mask';




@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BaseButtonModule,
    SidebarModule,
    TopbarModule,
    FontAwesomeModule,
    FontAwesomeLibraryModule,
    PagesModule,
    BrowserAnimationsModule,
    HttpClientModule,
    DialogsModule,
    HotToastModule.forRoot(),
    BaseLayoutModule,
    InputMaskModule,

  ],
  providers: [
    SecurityService,
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline', subscriptSizing: 'dynamic'}},
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {panelClass: 'dialog', disableClose: true}},
    {provide: MAT_MENU_DEFAULT_OPTIONS, useValue: {backdropClass: 'customMenuBackdrop', overlayPanelClass: 'customMenu'}},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
