import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {FormInputType} from "@util/types/models/index";
import {TableActionType} from "@util/types/enums";

export class PaginatedResult<T> {
  items: T[];
  meta: IPaginationMeta;
  links?: IPaginationLinks
}

export interface IPaginationMeta {
  itemCount: number;
  totalItems: number;
  itemsPerPage: number;
  totalPages: number;
  currentPage: number;
}

export interface IPaginationLinks {
  first?: string;
  previous?: string;
  next?: string;
  last?: string;
}

// -- Helper -- //
function autoImplement<T>(): new () => T {
  return class { } as any;
}

export interface DropdownData {
  name: string;
  value: any;

  pluralName?: string;
  icon?: IconProp;
  backgroundColor?: string;
  textColor?: string;
  borderColor?: string;
  alternateColors?: { backgroundColor: string, textColor: string, borderColor: string };
  action?: (any) => void;
}

export interface DropdownDataWithDisabled extends DropdownData {
  disabled?: boolean;
}

export interface DropdownDataWithTags extends DropdownData {
  tags: string[];
}

export interface DropdownDataWithTemplates extends DropdownData {
  templates: string[];
}

// TODO: Should make extended types, so all keys aren't in a single object.
//    e.a. FormSelectInput, with selectOptions as a required field, etc.
export interface FormInput {
  label?: string;
  placeholder?: string;
  filterLabel?: string;
  type?: FormInputType | string; // FIXME: Some forms still have the type hardcoded, this should be changed to the enum.
  key?: string;
  formGroupName?: string;
  selectOptions?: (DropdownData | DropdownDataWithDisabled | DropdownDataWithTags | DropdownDataWithTemplates)[];
  multi?: boolean; // enables multi select for selectOptions
  visible?: boolean; // Hides the input
  disabled?: boolean; // Disables the input
  alternateStyling?: boolean; // Checkbox can have alternate styling
  withBorder?: boolean; // FIXME
  children?: FormInput[]; // Use this to put the input fields in a row, instead of a column. Maybe rename this.
  actionButton?: { label: string, action: () => void };
}

export interface PopoverFormSection {
  label: string;
  inputs: FormInput[]; // TODO: this should have typing.
  actionButton?: { label: string, action: () => void };
}

export interface FilterData extends DropdownData {
  key: string;
  active: boolean;
}

export interface TableColumn {
  headerText: string;
  key: string;
  sortable?: boolean;
  sortKey?: string;
  actions?: { headerActions?: TableActionType[], rowActions?: TableActionType[], noMenuActions?: string[] };
  classes?: string; // Custom css classes
  // TODO: parse should be a function that returns a string (essentially how "func" is used), all these generic implementations should be utils that can be used in the parse function.
  // "capitalize" is actually more like bold/strong... it doesn't capitalize but rather makes the text bold.
  parse?: { array?: DropdownData[], isDate?: boolean, dateFormat?: string, isName?: boolean, isImage?: boolean, isNameValue?: boolean, isDoB?: boolean, isFullName?: boolean, func?: (value: any) => string, capitalize?: boolean}; // TODO: ability to pass function?
  combine?: string; // Adds a value behind the first value
  filter?: FilterData[];
}

export const DefaultPaginationValues = {
  PageSizeOptions: [10, 20, 25, 50],
  PageSize: 20
}

export const DefaultPagination = {
  itemCount: 0,
  totalItems: 0,
  itemsPerPage: DefaultPaginationValues.PageSize,
  totalPages: 1,
  currentPage: 1
} as IPaginationMeta;

export enum FormEventType {
  Upload = 'upload'
}

export class FormEvent {
  type: FormEventType;
  data: any;
}

export enum ExamType {
  FundusScreening = 'fundus-screening',
  Rijges095 = 'rijges-095',
  Rijges107 = 'rijges-107',
  Rijges104 = 'rijges-104',
  GlaucomaScreening = 'glaucoma-screening',
  OptometricExamination = 'optometric-examination',
  GlassesMeasurement = 'glasses-measurement',
  OrthopticExamination = 'orthoptic-examination',
  DryEyes = 'dry-eyes'
}
