// DO NOT MAKE CHANGES TO THIS FILE. This file is automatically generated! Add custom models to the _template.ts file!
import {MatTableDataSource} from "@angular/material/table";
import {Type} from "class-transformer";
import {FormControl} from "@angular/forms";
// import {Point} from "geojson";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
// import {InferSubjects, MongoAbility} from "@casl/ability";
type TCreateFormEntry = Omit<FormEntry, 'id' >;
export class CreateFormEntry extends autoImplement<TCreateFormEntry>() {
}
export declare type ClassType<T> = {};
export class FormEntry {
    id: string;
    parent: any;
    parentId: string;
    form: Form;
    formId: string;
    type: FormEntryType;
    status: FormEntryStatus;
    data: any;
    readonly modelName = "FormEntry"
}
type TUpdateFormEntry = Partial<Omit<FormEntry, 'id' >>;
export class UpdateFormEntry extends autoImplement<TUpdateFormEntry>() {
}
type TCreateForm = Omit<Form, 'id' >;
export class CreateForm extends autoImplement<TCreateForm>() {
}
type TCreateFormField = Omit<FormField, 'id' >;
export class CreateFormField extends autoImplement<TCreateFormField>() {
}
export class FormField {
    id: string;
    label: string;
    subLabel?: string;
    type: FormInputType;
    key: string;
    initialValue?: any;
    checkAction?: string;
    validators?: any[];
    message?: string;
    children?: FormField[];
    parent?: FormField;
    readonly modelName = "FormField"
}
type TUpdateFormField = Partial<Omit<FormField, 'id' >>;
export class UpdateFormField extends autoImplement<TUpdateFormField>() {
}
type TCreateFormSection = Omit<FormSection, 'id' >;
export class CreateFormSection extends autoImplement<TCreateFormSection>() {
}
export class FormSection {
    id: string;
    label: string;
    fields: FormField[];
    form?: Form;
    formId?: string;
    readonly modelName = "FormSection"
}
type TUpdateFormSection = Partial<Omit<FormSection, 'id' >>;
export class UpdateFormSection extends autoImplement<TUpdateFormSection>() {
}
export class FormStructure {
    index: number;
    questions: Record<string, any>;
    readonly modelName = "FormStructure"
}
export class Form {
    id: string;
    identifier: string;
    name: string;
    type: FormType;
    // @Type(() => FormStructure)
    structure: FormStructure;
    readonly modelName = "Form"
}
type TUpdateForm = Partial<Omit<Form, 'id' >>;
export class UpdateForm extends autoImplement<TUpdateForm>() {
}
    export enum UserRole {
        Generic = "generic",
        Admin = "admin",
    }

    export enum FormType {
        Generic = 'generic'
    }

    export enum FormEntryType {
        Example = 'example',
    }

    export enum FormEntryStatus {
        Open = 'open',
        Closed = 'closed',
    }

export class PaginatedResult<T> {
    items: T[];
    meta: IPaginationMeta;
    links?: IPaginationLinks
}

export interface IPaginationMeta {
    itemCount: number;
    totalItems: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
}

export interface IPaginationLinks {
    first?: string;
    previous?: string;
    next?: string;
    last?: string;
}

// -- Helper -- //
function autoImplement<T>(): new () => T {
    return class { } as any;
}

export interface DropdownData {
    name: string;
    value: any;

    pluralName?: string;
    icon?: IconProp;
    backgroundColor?: string;
    textColor?: string;
    borderColor?: string;
    alternateColors?: { backgroundColor: string, textColor: string, borderColor: string };
    action?: (any) => void;
}

export interface DropdownDataWithDisabled extends DropdownData {
    disabled?: boolean;
}

export interface DropdownDataWithTags extends DropdownData {
    tags: string[];
}

export interface DropdownDataWithTemplates extends DropdownData {
    templates: string[];
}

// TODO: Should make extended types, so all keys aren't in a single object.
//    e.a. FormSelectInput, with selectOptions as a required field, etc.
export interface FormInput {
    label?: string;
    placeholder?: string;
    filterLabel?: string;
    type?: FormInputType | string; // FIXME: Some forms still have the type hardcoded, this should be changed to the enum.
    key?: string;
    formGroupName?: string;
    selectOptions?: (DropdownData | DropdownDataWithDisabled | DropdownDataWithTags | DropdownDataWithTemplates)[];
    multi?: boolean; // enables multi select for selectOptions
    visible?: boolean; // Hides the input
    disabled?: boolean; // Disables the input
    alternateStyling?: boolean; // Checkbox can have alternate styling
    withBorder?: boolean; // FIXME
    children?: FormInput[]; // Use this to put the input fields in a row, instead of a column. Maybe rename this.
    actionButton?: { label: string, action: () => void };
}

export interface PopoverFormSection {
    label: string;
    inputs: FormInput[]; // TODO: this should have typing.
    actionButton?: { label: string, action: () => void };
}

export interface TableColumn {
    headerText: string;
    key: string;
    sortable?: boolean;
    actions?: { headerActions?: any[], rowActions?: any[], noMenuActions?: string[] };
    classes?: string | ((column, value) => string); // Custom css classes
    // TODO: parse should be a function that returns a string (essentially how "func" is used), all these generic implementations should be utils that can be used in the parse function.
    parse?: { array?: DropdownData[], isDate?: boolean, dateFormat?: string, isName?: boolean, isImage?: boolean, isNameValue?: boolean, isDoB?: boolean, isFullName?: boolean, func?: (value: any) => string}; // TODO: ability to pass function?
    combine?: string; // Adds a value behind the first value
}

export const DefaultPaginationValues = {
    PageSizeOptions: [10, 20, 25, 50],
    PageSize: 20
}

export const DefaultPagination = {
    itemCount: 0,
    totalItems: 0,
    itemsPerPage: DefaultPaginationValues.PageSize,
    totalPages: 1,
    currentPage: 1
} as IPaginationMeta;

export enum FormEventType {
    Upload = 'upload'
}

export class FormEvent {
    type: FormEventType;
    data: any;
}

export class AgbZorgverlener {
    id: string;
    agbCode: string;
    zorgsoortCode: string;
    zorgsoortOmschrijving: string;
    readonly modelName = "AgbZorgverlener"
}
type TCreateAgbZorgverlener = Omit<AgbZorgverlener, 'id' >;
export class CreateAgbZorgverlener extends autoImplement<TCreateAgbZorgverlener>() {
}
type TUpdateAgbZorgverlener = Partial<Omit<AgbZorgverlener, 'id' >>;
export class UpdateAgbZorgverlener extends autoImplement<TUpdateAgbZorgverlener>() {
}
export class AppointmentTimelineItem {
    id: string;
    previousStatus: string;
    newStatus: string;
    @Type(() => User)
    createdBy: User;
    createdById: string;
    createdAt: Date;
    @Type(() => Appointment)
    appointment: Appointment;
    appointmentId: string;
    readonly modelName = "AppointmentTimelineItem"
}
export class Appointment {
    id: string;
    number: string;
    @Type(() => User)
    createdBy: User;
    createdAt: Date;
    updatedAt: Date;
    @Type(() => ExaminationRoom)
    examinationRoom: ExaminationRoom;
    examinationRoomId: string;
    @Type(() => CompanyLocation)
    companyLocation: CompanyLocation;
    companyLocationId: string;
    @Type(() => Patient)
    patient: Patient;
    patientId: string;
    patientHasPaid: boolean;
    dripStatus: DripStatus;
    @Type(() => ExaminationProtocol)
    examinationProtocol: ExaminationProtocol;
    examinationProtocolId: string;
    @Type(() => CalendarEntry)
    calendarEntry: CalendarEntry;
    calendarEntryId: string;
    @Type(() => User)
    user: User;
    userId: string;
    status: AppointmentStatus;
    remarks: string;
    paymentType: PaymentType;
    channel: AppointmentChannel;
    @Type(() => AppointmentTimelineItem)
    timelineItems: AppointmentTimelineItem[];
    @Type(() => Referral)
    referral: Referral;
    referralId: string;
    readonly modelName = "Appointment"
}
type TCreateAppointment = Omit<Appointment, 'id' >;
export class CreateAppointment extends autoImplement<TCreateAppointment>() {
}
export class DripStatus {
    timestamp: Date;
    eyeSide: EyeScanSide;
    dripsUsed: EyeDripType[];
}
type TUpdateAppointment = Partial<Omit<Appointment, 'id'| 'companyLocationId'| 'user'| "companyLocation"| "examinationRoom" >>;
export class UpdateAppointment extends autoImplement<TUpdateAppointment>() {
}
export class Audit {
    id: string;
    createdAt: Date;
    entityName: string;
    entityId: string;
    action: string;
    ip: string;
    bytesReceived: number;
    statusCode: string;
    method: string;
    duration: number;
    url: string;
    @Type(() => NestedUser)
    user: User;
    userId: string;
    dataBefore: any;
    dataAfter: any;
    readonly modelName = "Audit"
}
type TCreateAudit = Omit<Audit, 'id' >;
export class CreateAudit extends autoImplement<TCreateAudit>() {
}
type TUpdateAudit = Partial<Omit<Audit, 'id' >>;
export class UpdateAudit extends autoImplement<TUpdateAudit>() {
}
export class StoredExplicitAbility {
    can: boolean;
    action: Action;
    subject: Extract<any, string>; // Subjects
    conditions?: any;
    id?: any;
    expiration: Date;
}
export class AvailabilitySchedule {
    id: string;
    createdAt: Date;
    updatedAt: Date;
    @Type(() => Date)
    @Type(() => Date)
    date: Date;
    availabilities: Availability[];
    note: string;
    userId: string;
    @Type(() => User)
    user: User;
    createdById: string;
    @Type(() => User)
    createdBy: User;
    companyLocationId: string;
    @Type(() => CompanyLocation)
    companyLocation: CompanyLocation;
    @Type(() => ExaminationRoom)
    examinationRoom: ExaminationRoom;
    examinationRoomId: string;
    readonly modelName = "AvailabilitySchedule"
}
type TCreateAvailabilitySchedule = Omit<AvailabilitySchedule, 'id'| 'companyLocation'| 'availabilities'| 'createdBy'| 'createdById'| 'user'| 'date' >;
export class CreateAvailabilitySchedule extends autoImplement<TCreateAvailabilitySchedule>() {
    date: string;
    @Type(() => TimeRange)
    range: TimeRange;
    @Type(() => TimeRange)
    break: TimeRange;
    @Type(() => OnlineAvailability)
    @Type(() => OnlineAvailability)
    availabilities: OnlineAvailability[];
}
export class OnlineAvailability {
    @Type(() => TimeRange)
    range: TimeRange;
    examinationProtocolIds: ExaminationProtocol[];
}
export class TimeRange {
    start: string;
    end: string;
}
type TUpdateAvailabilitySchedule = Partial<Omit<AvailabilitySchedule, 'id'| 'companyLocationId' >>;
export class UpdateAvailabilitySchedule extends autoImplement<TUpdateAvailabilitySchedule>() {
}
export class Availability {
    id: string;
    timeRange: DateTimeRange;
    type: AvailabilityType;
    @Type(() => User)
    user: User;
    userId: string;
    @Type(() => CompanyLocation)
    companyLocation: CompanyLocation;
    companyLocationId: string;
    @Type(() => ExaminationRoom)
    examinationRoom: ExaminationRoom;
    examinationRoomId: string;
    examinationProtocols?: ExaminationProtocol[];
    readonly modelName = "Availability"
}
type TCreateAvailability = Omit<Availability, 'id'| 'examinationRoom'| 'user'| 'companyLocation' >;
export class CreateAvailability extends autoImplement<TCreateAvailability>() {
}
type TUpdateAvailability = Partial<Omit<Availability, 'id'| 'examinationRoom'| 'companyLocation'| 'user' >>;
export class UpdateAvailability extends autoImplement<TUpdateAvailability>() {
}
export class BillingInformation {
    id: number;
    readonly modelName = "BillingInformation"
}
type TCreateBillingInformation = Omit<BillingInformation, 'id' >;
export class CreateBillingInformation extends autoImplement<TCreateBillingInformation>() {
}
type TUpdateBillingInformation = Partial<Omit<BillingInformation, 'id' >>;
export class UpdateBillingInformation extends autoImplement<TUpdateBillingInformation>() {
}
export class CalendarEntry {
    id: string;
    @Type(() => Date)
    start: Date;
    @Type(() => Date)
    end: Date;
    type: CalendarEntryType;
    subType: CalendarEntryBlockType;
    readonly modelName = "CalendarEntry"
}
type TCreateCalendarEntry = Omit<CalendarEntry, 'id' >;
export class CreateCalendarEntry extends autoImplement<TCreateCalendarEntry>() {
}
type TUpdateCalendarEntry = Partial<Omit<CalendarEntry, 'id' >>;
export class UpdateCalendarEntry extends autoImplement<TUpdateCalendarEntry>() {
}
export class CompanyLocation {
    id: string;
    number: string;
    name: string;
    type: CompanyLocationType;
    @Type(() => Company)
    company?: Company;
    companyId?: string;
    @Type(() => ExaminationRoom)
    examinationRooms: ExaminationRoom[];
    @Type(() => ExaminationProtocol)
    examinationProtocols: ExaminationProtocol[];
    @Type(() => User)
    users: User[];
    @Type(() => UserToLocation)
    userLocations: UserToLocation[];
    @Type(() => Address)
    address: Address;
    geo: any; // Point
    openingHours: OpeningHours;
    @Type(() => Equipment)
    equipment: Equipment[];
    @Type(() => RemoteDocument)
    attachments: RemoteDocument[];
    phoneNumber: string;
    phoneNumber2: string;
    isPubliclyFindable: boolean;
    email: string;
    @Type(() => User)
    contactPersons: User[];
    readonly modelName = "CompanyLocation"
}
type TCreateCompanyLocation = Omit<CompanyLocation, 'id' >;
export class CreateCompanyLocation extends autoImplement<TCreateCompanyLocation>() {
}
export class FromToHours {
    public start: string;
    public end: string;
    public closed: boolean;
}

type TGeoCompanyLocation = Omit<CompanyLocation, 'geo'| 'contactPersons'| 'equipment'| 'attachments'| 'users'| 'userLocations'| 'examinationProtocols'| 'examinationRooms'| 'openingHours' >;
export class GeoCompanyLocation extends autoImplement<TGeoCompanyLocation>() {
    distance: number;
}
type TNestedCompanyLocation = Omit<CompanyLocation, 'id'| 'address'| 'geo'| 'openingHours' >;
export class NestedCompanyLocation extends autoImplement<TNestedCompanyLocation>() {
}
export class OpeningHours {
    public mon: FromToHours;
    public tue: FromToHours;
    public wed: FromToHours;
    public thu: FromToHours;
    public fri: FromToHours;
    public sat: FromToHours;
    public sun: FromToHours;
}
type TUpdateCompanyLocation = Partial<Omit<CompanyLocation, 'id'| 'companyId' >>;
export class UpdateCompanyLocation extends autoImplement<TUpdateCompanyLocation>() {
}
export class Company {
    id: string;
    number: string;
    @Type(() => Company)
    parent?: Company;
    parentId?: string;
    @Type(() => Company)
    children?: Company[];
    name: string;
    @Type(() => CompanyLocation)
    locations: CompanyLocation[];
    @Type(() => UserToCompany)
    users: UserToCompany[];
    type: CompanyType;
    @Type(() => Address)
    address: Address;
    kvk: string;
    btw: string;
    iban: string;
    sendInvoicesTo: CompanyInvoiceType;
    email: string;
    phoneNumber: string;
    phoneNumber2: string;
    website: string;
    @Type(() => RemoteDocument)
    attachments: RemoteDocument[];
    @Type(() => User)
    contactPersons: User[];
    agbCode: string;
    readonly modelName = "Company"
}
type TCreateCompany = Omit<Company, 'id' >;
export class CreateCompany extends autoImplement<TCreateCompany>() {
}
type TUpdateCompany = Partial<Omit<Company, 'id' >>;
export class UpdateCompany extends autoImplement<TUpdateCompany>() {
}
export class Consult {
    id: string;
    @Type(() => NestedUser)
    createdBy: NestedUser;
    createdById: string;
    createdAt: Date;
    @Type(() => Consult)
    parent: Consult;
    parentId: string;
    @Type(() => Patient)
    patient: Patient;
    patientId: string;
    @Type(() => Appointment)
    appointment: Appointment;
    appointmentId: string;
    @Type(() => ExaminationRoom)
    examinationRoom: ExaminationRoom;
    examinationRoomId: string;
    @Type(() => NestedCompanyLocation)
    companyLocation: NestedCompanyLocation;
    companyLocationId: string;
    @Type(() => Exam)
    exam: Exam;
    examId: string;
    type: ConsultType;
    status: ConsultStatus;
    data: BaseFormDto;
    attachments: RemoteDocument[];
    readonly modelName = "Consult"
}
type TCreateConsult = Omit<Consult, 'id'| 'appointmentId'| 'parentId'| 'companyLocationId'| 'type'| 'status' >;
export class CreateConsult extends autoImplement<TCreateConsult>() {
}
type TUpdateConsult = Partial<Omit<Consult, 'id' >>;
export class UpdateConsult extends autoImplement<TUpdateConsult>() {
}
export abstract class BaseFormDto {
}
export class ConversationMessage {
    id: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
    @Type(() => ConversationUser)
    createdBy: ConversationUser;
    createdById: string;
    @Type(() => Conversation)
    conversation: Conversation;
    conversationId: string;
    text: string;
    @Type(() => ConversationMessage)
    replyTo: ConversationMessage;
    replyToId: string;
    @Type(() => RemoteDocument)
    attachments: RemoteDocument[];
    recipients: ConversationUser[];
    readonly modelName = "ConversationMessage"
}
type TCreateConversationMessage = Omit<ConversationMessage, 'id' >;
export class CreateConversationMessage extends autoImplement<TCreateConversationMessage>() {
}
type TUpdateConversationMessage = Partial<Omit<ConversationMessage, 'id'| 'attachments' >>;
export class UpdateConversationMessage extends autoImplement<TUpdateConversationMessage>() {
}
export class ConversationUpdate {
    id: string;
    createdAt: Date;
    @Type(() => ConversationUser)
    createdBy: ConversationUser;
    createdById: string;
    @Type(() => Conversation)
    conversation: Conversation;
    conversationId: string;
    type: ConversationUpdateType;
    readonly modelName = "ConversationUpdate"
}
type TCreateConversationUpdate = Omit<ConversationUpdate, 'id' >;
export class CreateConversationUpdate extends autoImplement<TCreateConversationUpdate>() {
}
type TUpdateConversationUpdate = Partial<Omit<ConversationUpdate, 'id' >>;
export class UpdateConversationUpdate extends autoImplement<TUpdateConversationUpdate>() {
}
export class Conversation {
    id: string;
    createdAt: Date;
    @Type(() => NestedUser)
    createdBy: NestedUser;
    createdById: string;
    messages: ConversationMessage[];
    @Type(() => ConversationMessage)
    lastMessage: ConversationMessage;
    @Type(() => ConversationUser)
    users: ConversationUser[];
    patientId: string;
    @Type(() => Patient)
    patient: Patient;
    updates: ConversationUpdate[];
    colors: string[];
    readonly modelName = "Conversation"
}
type TCreateConversation = Omit<Conversation, 'id'| 'users' >;
export class CreateConversation extends autoImplement<TCreateConversation>() {
    users?: string[];
}
type TUpdateConversation = Partial<Omit<Conversation, 'id' >>;
export class UpdateConversation extends autoImplement<TUpdateConversation>() {
}
type TCreateDataTransaction = Omit<DataTransaction, 'id' >;
export class CreateDataTransaction extends autoImplement<TCreateDataTransaction>() {
}
export class DataTransaction {
    id: string;
    @Type(() => Patient)
    patient?: Patient;
    patientId?: string;
    readonly modelName = "DataTransaction"
}
type TUpdateDataTransaction = Partial<Omit<DataTransaction, 'id' >>;
export class UpdateDataTransaction extends autoImplement<TUpdateDataTransaction>() {
}
type TCreateEquipment = Omit<Equipment, 'id'| 'attachments' >;
export class CreateEquipment extends autoImplement<TCreateEquipment>() {
}
export class Equipment {
    id: string;
    number: string;
    name: string;
    type: EquipmentType;
    brand: string;
    model: string;
    constructionYear: string;
    constructionMonth: string;
    @Type(() => RemoteDocument)
    attachments: RemoteDocument[];
    supplier: string;
    previousService: Date;
    nextService: Date;
    maintenanceCompany: string;
    status: string;
    memo: string;
    @Type(() => ExaminationRoom)
    examinationRoom: ExaminationRoom;
    examinationRoomId: string;
    @Type(() => CompanyLocation)
    companyLocation: CompanyLocation;
    companyLocationId: string;
    readonly modelName = "Equipment"
}
type TUpdateEquipment = Partial<Omit<Equipment, 'id'| 'attachments' >>;
export class UpdateEquipment extends autoImplement<TUpdateEquipment>() {
}
type TCreateExam = Omit<Exam, 'id'| 'examinationProtocolId'| 'createdBy'| 'createdById'| 'actionRequiredBy'| 'actionRequiredById'| 'actionRequiredFor'| 'actionRequiredForId'| 'status' >;
export class CreateExam extends autoImplement<TCreateExam>() {
    examIdentifier: string;
}
export class ExamFinalize {
    finalizeMethod: FinalizeMethod;
    zdNumberOption: FinalizeZdNumberOptions;
    remarks: string;
}
export class ExamTimelineItem {
    id: string;
    previousStatus: string;
    previousDisplayStatus: string;
    newStatus: string;
    newDisplayStatus: string;
    @Type(() => User)
    createdBy: User;
    createdById: string;
    createdAt: Date;
    @Type(() => User)
    nextOwner: User;
    nextOwnerId: number;
    @Type(() => Appointment)
    appointment: Appointment;
    appointmentId: string;
    readonly modelName = "ExamTimelineItem"
}
export class Exam {
    id: string;
    number: string;
    createdAt: Date;
    updatedAt: Date;
    @Type(() => NestedUser)
    createdBy: NestedUser;
    createdById: string;
    @Type(() => NestedUser)
    actionRequiredBy: NestedUser;
    actionRequiredById: string;
    @Type(() => Consult)
    actionRequiredFor: Consult;
    actionRequiredForId: string;
    @Type(() => Patient)
    patient: Patient;
    patientId: string;
    @Type(() => ExaminationProtocol)
    examinationProtocol: ExaminationProtocol;
    examinationProtocolId: string;
    @Type(() => NestedCompanyLocation)
    companyLocation: NestedCompanyLocation;
    companyLocationId: string;
    status: ExamStatus;
    displayStatus: ExamDisplayStatus;
    @Type(() => Consult)
    consults: Consult[];
    @Type(() => ExamTimelineItem)
    timelineItems: ExamTimelineItem[];
    readonly modelName = "Exam"
}
export class SMSCode {
    code: string;
}
type TUpdateExam = Partial<Omit<Exam, 'id'| 'examinationProtocol'| 'examinationProtocolId'| 'createdBy'| 'createdById'| 'patient' >>;
export class UpdateExam extends autoImplement<TUpdateExam>() {
}
type TCreateExaminationConsult = Omit<ExaminationConsult, 'id' >;
export class CreateExaminationConsult extends autoImplement<TCreateExaminationConsult>() {
}
export class ExaminationConsult {
    id: string;
    index: number;
    role: UserRole;
    questions: Record<string, any>;
    transferDataKeys: Record<string, any>;
    nextExamStatus: ExamStatus;
    readonly modelName = "ExaminationConsult"
}
type TUpdateExaminationConsult = Partial<Omit<ExaminationConsult, 'id' >>;
export class UpdateExaminationConsult extends autoImplement<TUpdateExaminationConsult>() {
}
type TCreateExaminationProtocol = Omit<ExaminationProtocol, 'id' >;
export class CreateExaminationProtocol extends autoImplement<TCreateExaminationProtocol>() {
}
export class ExaminationProtocol {
    id: string;
    examinationTypeId: string;
    @Type(() => ExaminationType)
    examinationType?: ExaminationType;
    locationId: string;
    @Type(() => CompanyLocation)
    location?: CompanyLocation;
    length: number;
    price: number;
    active: boolean;
    defaultGraderId: string;
    readonly modelName = "ExaminationProtocol"
}
type TUpdateExaminationProtocol = Partial<Omit<ExaminationProtocol, 'id' >>;
export class UpdateExaminationProtocol extends autoImplement<TUpdateExaminationProtocol>() {
}
type TCreateExaminationRoom = Omit<ExaminationRoom, 'id' >;
export class CreateExaminationRoom extends autoImplement<TCreateExaminationRoom>() {
}
export class ExaminationRoom {
    id: string;
    name: string;
    number: string;
    displayName: string;
    description: string;
    @Type(() => CompanyLocation)
    location: CompanyLocation;
    locationId: string;
    remarks: string;
    phoneNumber: string;
    @Type(() => Equipment)
    equipment: Equipment[];
    readonly modelName = "ExaminationRoom"
}
type TUpdateExaminationRoom = Partial<Omit<ExaminationRoom, 'id' >>;
export class UpdateExaminationRoom extends autoImplement<TUpdateExaminationRoom>() {
}
type TCreateExaminationType = Omit<ExaminationType, 'id' >;
export class CreateExaminationType extends autoImplement<TCreateExaminationType>() {
}
export class ExaminationType {
    id: string;
    identifier: ExamType;
    name: string;
    warning: string;
    defaultLength: number;
    defaultPrice: number;
    @Type(() => ExaminationConsult)
    consults: ExaminationConsult[];
    color: string;
    readonly modelName = "ExaminationType"
}
type TUpdateExaminationType = Partial<Omit<ExaminationType, 'id' >>;
export class UpdateExaminationType extends autoImplement<TUpdateExaminationType>() {
}
type TCreateExternalCommunicationRecordDto = Omit<ExternalCommunicationRecordDto, 'id'| 'sentAt'| 'status' >;
export class CreateExternalCommunicationRecordDto extends autoImplement<TCreateExternalCommunicationRecordDto>() {
}
export class ExternalCommunicationRecordDto {
    id: string;
    createdAt: Date;
    updatedAt: Date;
    communicationType: ExternalCommunicationType;
    sentAt: Date;
    @Type(() => RecipientDto)
    @Type(() => RecipientDto)
    recipient: RecipientDto;
    status: ExternalCommunicationRecordStatus;
    remoteDocumentId: string;
    @Type(() => RemoteDocument)
    @Type(() => RemoteDocument)
    remoteDocument: RemoteDocument;
    xmlData: any;
    readonly modelName = "ExternalCommunicationRecord"
}
export class RecipientDto {
    recipientId: string;
    recipientType: RecipientType;
    @Type(() => HealthcareParty)
    healthcareParty?: HealthcareParty;
    @Type(() => Patient)
    patient?: Patient;
    readonly modelName = "Recipient"
}
type TUpdateExternalCommunicationRecordDto = Partial<Omit<ExternalCommunicationRecordDto, 'id'| 'sentAt'| 'status' >>;
export class UpdateExternalCommunicationRecordDto extends autoImplement<TUpdateExternalCommunicationRecordDto>() {
}
type TCreateExternalCommunication = Omit<ExternalCommunication, 'id'| 'status' >;
export class CreateExternalCommunication extends autoImplement<TCreateExternalCommunication>() {
}

export class Elements {
    type: string;
    value: any;
}

export class ExternalCommunication {
    createdAt: Date;
    updatedAt: Date;
    id: string;
    @Type(() => Elements)
    elements: Elements[];
    @Type(() => ExternalCommunicationRecordDto)
    records: CreateExternalCommunicationRecordDto[];
    status: ExternalCommunicationStatus;
    patientId: string;
    @Type(() => Patient)
    patient: Patient;
    subject: string;
    template: string;
    readonly modelName = "ExternalCommunication"
}
type TUpdateExternalCommunication = Partial<Omit<ExternalCommunication, 'id' >>;
export class UpdateExternalCommunication extends autoImplement<TUpdateExternalCommunication>() {
}
type TCreateGroup = Omit<Group, 'id' >;
export class CreateGroup extends autoImplement<TCreateGroup>() {
}
export class Group {
    id: string;
    users: User[];
    readonly modelName = "Group"
}
type TUpdateGroup = Partial<Omit<Group, 'id' >>;
export class UpdateGroup extends autoImplement<TUpdateGroup>() {
}
type TCreateHealthcareParty = Omit<HealthcareParty, 'id' >;
export class CreateHealthcareParty extends autoImplement<TCreateHealthcareParty>() {
}
export class HealthcareParty {
    id: string;
    agbCode: string;
    bigNumber: string;
    zorgMailId: string;
    zorgMailAddress: string;
    zorgMailOrganizationId: string;
    type: HealthcarePartyType;
    lastSyncedAt: Date;
    data: any;
    relations: any;
    readonly modelName = "HealthcareParty"
}
type TUpdateHealthcareParty = Partial<Omit<HealthcareParty, 'id' >>;
export class UpdateHealthcareParty extends autoImplement<TUpdateHealthcareParty>() {
}
export class Address {
    postalCode: string;
    houseNr: string;
    houseNrSuffix?: string;
    city?: string;
    streetName: string;
    country?: string;
    municipality?: string;
}
export class Name {
    initials: string;
    firstName: string;
    middleName: string;
    lastName: string;
}
export class InsuranceData {
    status: VecozoInsuranceStatus;
    uzovi: string;
    insuranceNumber: string;
    type?: VecozoInsuranceType;
    packageCode?: string;
    packageName?: string;
    labelCode?: string;
    labelName?: string;
    startDate?: Date;
    endDate?: Date;
}
export class InsureeData {
}
type TCreateInvoice = Omit<Invoice, 'id' >;
export class CreateInvoice extends autoImplement<TCreateInvoice>() {
}
export class Invoice {
    id: number;
    number: string;
    date: Date;
    status: InvoiceStatus;
    @Type(() => Company)
    company: Company;
    companyId: string;
    @Type(() => RemoteDocument)
    remoteDocument: RemoteDocument;
    remoteDocumentId: string;
    readonly modelName = "Invoice"
}
type TUpdateInvoice = Partial<Omit<Invoice, 'id' >>;
export class UpdateInvoice extends autoImplement<TUpdateInvoice>() {
}
type TCreateMemo = Omit<Memo, 'id'| 'createdAt'>;
export class CreateMemo extends autoImplement<TCreateMemo>() {
}
export class Memo {
    id: string;
    createdAt: Date;
    @Type(() => User)
    createdBy: User;
    content: string;
    type: MemoType;
    readonly modelName = "Memo"
}
type TUpdateMemo = Partial<Omit<Memo, 'id' >>;
export class UpdateMemo extends autoImplement<TUpdateMemo>() {
}
type TCreateNotification = Omit<Notification, 'id' >;
export class CreateNotification extends autoImplement<TCreateNotification>() {
}
export class Notification {
    id: string;
    @Type(() => User)
    createdBy: User;
    createdById: string;
    createdAt: Date;
    @Type(() => User)
    user: User;
    userId: string;
    @Type(() => Conversation)
    conversation?: Conversation;
    conversationId: string;
    @Type(() => ConversationMessage)
    replyTo?: ConversationMessage;
    replyToId: string;
    type: NotificationType;
    read: boolean;
    @Type(() => Referral)
    referral: Referral;
    referralId: string;
    readonly modelName = "Notification"
}
type TUpdateNotification = Partial<Omit<Notification, 'id' >>;
export class UpdateNotification extends autoImplement<TUpdateNotification>() {
}
type TCreateOrder = Omit<Order, 'id' >;
export class CreateOrder extends autoImplement<TCreateOrder>() {
}
export class Order {
    id: string;
    @Type(() => NestedUser)
    createdBy: NestedUser;
    createdById: string;
    createdAt: Date;
    updatedAt: Date;
    @Type(() => Date)
    date: Date;
    @Type(() => Order)
    parent: Order;
    parentId: string;
    @Type(() => ResponsibleParty)
    responsibleParty: ResponsibleParty;
    responsiblePartyId: string;
    @Type(() => Patient)
    patient: Patient;
    patientId: string;
    @Type(() => CompanyLocation)
    location: CompanyLocation;
    locationId: string;
    type: OrderType;
    subTypes: OrderSubType[];
    specificExaminationTypes: SpecificExaminationType[];
    eyeSides: EyeScanSide[];
    status: OrderStatus;
    data: BaseFormDto;
    @Type(() => Consult)
    consult: Consult;
    consultId: string;
    @Type(() => Order)
    children: Order[];
    remarks: string;
    readonly modelName = "Order"
}
type TUpdateOrder = Partial<Omit<Order, 'id' >>;
export class UpdateOrder extends autoImplement<TUpdateOrder>() {
}
type TCreatePatient = Omit<Patient, 'id'| 'exams'| 'companies' >;
export class CreatePatient extends autoImplement<TCreatePatient>() {
}
export class Patient {
    id: string;
    number: string;
    email: string;
    phoneNumber?: string;
    phoneNumber2?: string;
    phoneNumber3?: string;
    initials?: string;
    firstName: string;
    middleName?: string;
    lastName?: string;
    partnerMiddleName?: string;
    partnerLastName?: string;
    bsn: string;
    bsnOrigin: BSNOriginType;
    @Type(() => Date)
    dateOfBirth: Date;
    gender: Gender;
    @Type(() => Address)
    address: Address;
    @Type(() => Appointment)
    appointments: Appointment[];
    @Type(() => Memo)
    memos: Memo[];
    @Type(() => Exam)
    exams: Exam[];
    @Type(() => RemoteDocument)
    attachments: RemoteDocument[];
    externalStorageId: string;
    externalStorageType: RemoteDocProtocol;
    idType: IDType;
    idNumber: string;
    @Type(() => Company)
    companies: Partial<Company>[];
    @Type(() => User)
    caregivers: Partial<User>[];
    remarks: string;
    @Type(() => ExternalCommunication)
    externalCommunications: Partial<ExternalCommunication>[];
    @Type(() => Referral)
    referrals: Partial<Referral>[];
    readonly modelName = "Patient"
}
export class RestrictedPatient {
    id: string;
    number: string;
    email: string;
    phoneNumber?: string;
    phoneNumber2?: string;
    phoneNumber3?: string;
    initials?: string;
    firstName: string;
    middleName?: string;
    lastName?: string;
    partnerMiddleName?: string;
    partnerLastName?: string;
    @Type(() => Date)
    dateOfBirth: Date;
    gender: Gender;
    @Type(() => Address)
    address: Address;
    readonly modelName = "Patient"
}
type TUpdatePatient = Partial<Omit<Patient, 'id'| 'exams'| 'companies' >>;
export class UpdatePatient extends autoImplement<TUpdatePatient>() {
}
type TCreatePayment = Omit<Payment, 'id' >;
export class CreatePayment extends autoImplement<TCreatePayment>() {
}
export class Payment {
    id: number;
    readonly modelName = "Payment"
}
type TUpdatePayment = Partial<Omit<Payment, 'id' >>;
export class UpdatePayment extends autoImplement<TUpdatePayment>() {
}
type TCreatePrincipalUser = Omit<PrincipalUser, 'id'| 'identities' >;
export class CreatePrincipalUser extends autoImplement<TCreatePrincipalUser>() {
    noSSOUser?: boolean;
    @Type(() => CreateUser)
    @Type(() => CreateUser)
    identities: CreateUser[];
}
export class PrincipalUser {
    id: string;
    number: string;
    authId: string;
    email: string;
    phoneNumber: string;
    @Type(() => String)
    @Type(() => String)
    roles: PrincipalUserRole[];
    @Type(() => User)
    @Type(() => User)
    identities: User[];
    remarks?: string;
    salutation: string;
    initials: string;
    firstName: string;
    middleName: string;
    lastName: string;
    gender: Gender;
    lastLogin: Date;
    activeIdentityId: string;
    activeIdentity: User;
    status: UserStatus;
    readonly modelName = "PrincipalUser"
}
type TUpdatePrincipalUser = Partial<Omit<PrincipalUser, 'id' >>;
export class UpdatePrincipalUser extends autoImplement<TUpdatePrincipalUser>() {
}
export class CompleteAppointment {
    paymentId: string;
    sessionId: string;
    @Type(() => Patient)
    patient: Patient;
    paymentType: PaymentType;
}
export class DateRange {
    from: string;
    to: string;
}
export class Geo {
    lat: string;
    long: string;
}
type TPublicAddress = Omit<Address, 'country' >;
export class PublicAddress extends autoImplement<TPublicAddress>() {
    geo: Geo;
    country: string;
}
export class PublicAppointment {
    companyLocationId: string;
    @Type(() => CalendarEntry)
    calendarEntry: CalendarEntry;
    examinationType: string;
}
export class PublicAvailability {
    id: string;
    hasAvailability: boolean;
    readonly modelName = "CompanyLocation"
}
export class PublicCompanyLocation {
    id: string;
    name: string;
    address: PublicAddress;
    availablePersonnelTypes: PublicPersonnelType[];
    availableExaminationTypes: PublicExaminationType[];
    openingHours: any; // TODO create an OpeningHours  and enforce type.
    readonly modelName = "CompanyLocation"
}
export class PublicExaminationType {
    name: string;
    prettyName: string;
    warning: string;
    price: string;
    duration: number;
}
export class PublicPersonnelType {
    role: string;
    name: string;
}
export class RequestAvailability {
    from: string;
    to: string;
    locations: string[];
}
type TCreateReferral = Omit<Referral, 'id' >;
export class CreateReferral extends autoImplement<TCreateReferral>() {
}
export class Referral {
    id: string;
    createdAt: Date;
    @Type(() => Patient)
    patient: Patient;
    patientId: string;
    newPatient: boolean;
    @Type(() => Company)
    sender: Company;
    senderId: string;
    @Type(() => Company)
    recipient: Company;
    recipientId: string;
    @Type(() => Company)
    destinationCompany: Company;
    destinationCompanyId: string;
    @Type(() => Company)
    destinationUser: Company;
    destinationUserId: string;
    patientInitials: string;
    patientMiddleName: string;
    patientLastname: string;
    patientBsn: string;
    patientDob: Date;
    patientPhone1: string;
    patientPhone2: string;
    patientPhone3: string;
    patientEmail: string;
    patientHouseNr: string;
    patientHouseNrSuffix: string;
    patientPostalCode: string;
    patientGender: Gender;
    senderName: string;
    zdNumber: string;
    zdDocuments: any[]; // TODO: typing.
    direction: ReferralDirection;
    documentName: string;
    @Type(() => Memo)
    memos: Memo[];
    @Type(() => Appointment)
    appointment: Appointment;
    appointmentId: string;
    status: ReferralStatus;
    readonly modelName = "Referral"
}
type TUpdateReferral = Partial<Omit<Referral, 'id' >>;
export class UpdateReferral extends autoImplement<TUpdateReferral>() {
}
type TCreateRemoteDocument = Omit<RemoteDocument, 'id' >;
export class CreateRemoteDocument extends autoImplement<TCreateRemoteDocument>() {
}
export class DocUpload {
    type: string;
    name: string;
    file?: any;
    eyeScanType: EyeScanType;
    eyeScanSide: EyeScanSide;
    consultId: string;
    status: InvoiceStatus;
}
export class RemoteDocument {
    id: string;
    createdAt: Date;
    name: string;
    uri: string;
    thumbnailUri?: string;
    thumbnailMimeType?: string;
    thumbnailData?: string;
    mimeType: string;
    protocol: string;
    type: RemoteDocType;
    eyeScanType: EyeScanType;
    eyeScanSide: EyeScanSide;
    @Type(() => User)
    createdBy: User;
    @Type(() => Memo)
    memos: Memo[];
    consultId: string;
    consult: Consult;
    status: InvoiceStatus; // TODO: Make this a more general enum? Don't know if it's gonna be used for more purposes
    readonly modelName = "RemoteDocument"
}
type TUpdateRemoteDocument = Partial<Omit<RemoteDocument, 'id' >>;
export class UpdateRemoteDocument extends autoImplement<TUpdateRemoteDocument>() {
}
type TCreateResponsibleParty = Omit<ResponsibleParty, 'id' >;
export class CreateResponsibleParty extends autoImplement<TCreateResponsibleParty>() {
}
export class ResponsibleParty {
    id: string;
    @Type(() => NestedUser)
    user: NestedUser;
    userId: string;
    @Type(() => Group)
    group: Group;
    groupId: string;
    type: ResponsiblePartyType;
    readonly modelName = "ResponsibleParty"
}
type TUpdateResponsibleParty = Partial<Omit<ResponsibleParty, 'id' >>;
export class UpdateResponsibleParty extends autoImplement<TUpdateResponsibleParty>() {
}
type TCreateSchedule = Omit<Schedule, 'id' >;
export class CreateSchedule extends autoImplement<TCreateSchedule>() {
    availability: Array<DateTimeRange>;
}
export class DateTimeRange {
    start: Date;
    end: Date;
}
export class Schedule {
    id: string;
    @Type(() => Date)
    @Type(() => Date)
    @Type(() => Date)
    @Type(() => Date)
    end?: Date;
    entries: DateTimeRange[];
    userId: string;
    companyLocationId: string;
    readonly modelName = "Schedule"
}
type TUpdateSchedule = Partial<Omit<Schedule, 'id' >>;
export class UpdateSchedule extends autoImplement<TUpdateSchedule>() {
}
export class TokenValidationResult {
    valid: boolean;
    usernameOrEmail?: string;
}
export class CompleteOnboarding {
    token: string;
    principalUserEmail?: string;
    @Type(() => CreatePrincipalUserForOnboarding)
    @Type(() => CreatePrincipalUserForOnboarding)
    createPrincipalUser?: CreatePrincipalUserForOnboarding;
}
type TConversationUser = Omit<User, 'agb'| 'big'| 'kp'| 'companies'| 'activeCompany'| 'locations' >;
export class ConversationUser extends autoImplement<TConversationUser>() {
    color?: string;
}
type TCreatePrincipalUserForOnboarding = Omit<CreatePrincipalUser, 'authId'| 'roles'| 'noSSOUser' >;
export class CreatePrincipalUserForOnboarding extends autoImplement<TCreatePrincipalUserForOnboarding>() {
    password: string;
}
type TCreateUser = Omit<User, 'id'| 'locations'| 'principalUser'| 'companies'| 'userLocations' >;
export class CreateUser extends autoImplement<TCreateUser>() {
    companies?: { locations?: { companyLocationId: string, roles?: UserRole[] }[]; roles?: UserRole[]; companyId: string; active?: boolean; }[];
}
type TNestedUser = Omit<User, 'roles'| 'companies'| 'activeCompany'| 'locations' >;
export class NestedUser extends autoImplement<TNestedUser>() {
}
type TUpdateUser = Partial<Omit<User, 'id'| 'principalUser'| 'principalUserId'| 'locations'| 'companies'| 'userLocations' >>;
export class UpdateUser extends autoImplement<TUpdateUser>() {
    companies?: { locations?: { companyLocationId: string, roles?: UserRole[] }[]; roles?: UserRole[]; companyId: string; active?: boolean; }[];
}
export class UserToCompany {
    id: string;
    roles: UserRole[];
    @Type(() => User)
    user: User;
    @Type(() => Company)
    company: Company;
    readonly modelName = "UserToCompany"
}
export class UserToLocation {
    id: string;
    roles: UserRole[];
    @Type(() => User)
    user: User;
    @Type(() => CompanyLocation)
    companyLocation: CompanyLocation;
    readonly modelName = "UserToLocation"
}
export class User {
    id: string;
    number: string;
    email: string;
    phoneNumber: string;
    phoneNumber2: string;
    salutation: string;
    initials: string;
    firstName: string;
    middleName: string;
    lastName: string;
    gender: Gender;
    agb: string;
    big: string;
    kp: string;
    roles: UserRole[];
    companies?: any[];
    activeCompanyId: string;
    activeCompany: any;
    activeLocationId: string;
    activeLocation: any;
    @Type(() => CompanyLocation)
    locations?: CompanyLocation[];
    @Type(() => UserToLocation)
    userLocations?: UserToLocation[];
    @Type(() => ExaminationProtocol)
    examinationProtocols?: ExaminationProtocol[];
    explicitAbilities?: StoredExplicitAbility[];
    principalUser: PrincipalUser;
    principalUserId: string;
    @Type(() => RemoteDocument)
    attachments: RemoteDocument[];
    remarks: string;
    healthcareRole: UserRole;
    validationKeysTurnedOff: {
        examType: string,
        keys: string[],
        consultIdx: string
    }[];
    readonly modelName = "User"
}
type TCreateZdDocument = Omit<ZdDocument, 'id' >;
export class CreateZdDocument extends autoImplement<TCreateZdDocument>() {
}
type TUpdateZdDocument = Partial<Omit<ZdDocument, 'id' >>;
export class UpdateZdDocument extends autoImplement<TUpdateZdDocument>() {
}
export class ZdDocument {
    id: string;
    patient: Patient;
    patientId: string;
    data: any;
    read: boolean;
    readonly modelName = "ZdDocument"
}
export enum RemoteDocProtocol {
    Local = 'local',
    GoogleDrive = 'google-drive',
    OneDrive = 'one-drive',
    Minio = 'minio',
}

export enum RemoteDocType {
    Generic = 'generic',
    EyeScan = 'eye_scan',
    Referral = 'referral',
    Invoice = 'invoice',
    Report = 'report'
}

export enum EyeScanType {
    Fundus = 'fundus',
    SlitLamp = 'slit_lamp',
    OCT_Macula = 'oct_macula',
    OCT_Papil = 'oct_papil',
    VisualField = 'visual_field',
    Topography = 'topography'
}

export enum EyeScanSide {
    OD = 'od',
    OS = 'os',
    ODS = 'ods'
}

export enum EyeDripType {
    Tropicamide = 'tropicamide',
    Fenylefrine = 'fenylefrine',
    Cyclopentolaat = 'cyclopentolaat'
}

export enum Gender {
    Male = 'male',
    Female = 'female',
    Other = 'other',
    Unknown = 'unknown',
}

export enum ReferralDirection {
    In = 'in',
    Out = 'out'
}

export enum ReferralStatus {
    Open = 'open',
    Planned = 'planned',
    Parked = 'parked',
    Closed = 'closed',
    Cancelled = 'cancelled',
}
export enum UserStatus {
    Active = 'active',
    Inactive = 'inactive',
}

// TODO: Fill with actual (correct) types.
export enum CompanyType {
    Unknown = 'unknown',
    Hospital = 'hospital',
    ZBC = 'zbc',
    Optician = 'optician',
    OptometricCenter = 'optometric-center',
    EyeDoctorZZP = 'eye-doctor',
    OptometristZZP = 'optometrist',
    DMVAuthority = 'dmv-authority', // Keuringsinstantie
    DMVDoctor = 'dmv-doctor' // Keuringsarts
}

export enum CompanyInvoiceType {
    Unknown = 'unknown',
    MainOrganization = 'main_organization',
    SubOrganization = 'sub_organization',
}


export enum CompanyLocationType {
    Unknown = 'unknown',
    OpticiansShop = 'optiekzaak',
    OptometricCenter = 'optometrisch-centrum',
    EyeClinic = 'oogkliniek',
}

export enum CalendarEntryType {
    Appointment = 'appointment',
    Break = 'break',
    Block = 'block',
    Note = 'note',
}

export enum CalendarEntryBlockType {
    Meeting = 'meeting',
    Personal = 'personal',
    Break = 'break',
    Counsel = 'counsel'
}

export enum AvailabilityType {
    Physical = 'physical', // work-hours.
    OnlinePlannable = 'online-plannable',
    Break = 'break'
}

export enum PrincipalUserRole {
    Admin = 'admin',
    Service = 'service',
    User = 'user',
}

export enum UserRole {
    // organisation specific roles.
    Optometrist = 'optometrist',
    Orthoptist = 'orthoptist',
    Optician = 'optician',
    ContactLensSpecialist = 'contact-lens-specialist',
    Ophthalmologist = 'ophthalmologist',
    CompanyManager = 'company-manager',
    LocationManager = 'location-manager',
    Planner = 'planner-scheduler',
    ContactPerson = 'contact-person'
}

export enum AppointmentStatus {
    Planned = 'planned',
    Canceled = 'canceled',
    Present = 'present',
    InTreatment = 'in-treatment',
    Preparation = 'preparation',
    EyeDoctor = 'eye-doctor',
    Finished = 'finished',
    NoShow = 'no-show',
    Pending = 'pending',
}

export enum PatientDripStatus {
    Dripped = 'dripped',
    NotDripped = 'not-dripped'
}

export enum PatientPaymentStatus {
    Paid = 'paid',
    NotPaid = 'not-paid'
}
export enum PaymentType {
    Mollie = 'mollie',
    PayInStore = 'pay-in-store',
}

export enum AppointmentChannel {
    Web = 'web',
    App = 'app'
}

export enum MemoType {
    Note = 'note'
}

export enum ExamStatus {
    Open = 'open',
    Cancelled = 'cancelled',
    Returned = 'returned',
    Submitted = 'submitted',
    Grading = 'grading',
    Graded = 'graded',
    Finalized = 'finalized',
    Closed = 'closed',
    SupervisionFinished = 'supervision-finished',
}

export enum ExamType {
    FundusScreening = 'fundus-screening',
    Rijges095 = 'rijges-095',
    Rijges107 = 'rijges-107',
    Rijges104 = 'rijges-104',
    GlaucomaScreening = 'glaucoma-screening',
    OptometricExamination = 'optometric-examination',
    GlassesMeasurement = 'glasses-measurement',
    OrthopticExamination = 'orthoptic-examination',
}

// TODO: this is a temporary solution, this will be a dynamic list.
export enum ExamDisplayStatus {
    Concept = 'concept',
    Grading = 'grading',
    Graded = 'graded',
    Finalized = 'finalized',
    ReturnedOptometrist = 'returned-optometrist',
    ReturnedOphthalmologist = 'returned-ophthalmologist',
    ReturnedOptician = 'returned-optician',
    AdditionalQuestion = 'additional-question',
    AdditionalQuestionAnswered = 'additional-question-answered',
    ExamRequested = 'exam-requested',
    ExamPlanned = 'exam-planned',
    ExamFinalized = 'exam-finalized',
    PhoneConsult = 'phone-consult',
    Cancelled = 'cancelled',
    SupervisionFinished = 'supervision-finished',
}

export enum ConsultStatus {
    Concept = 'concept',
    Submitted = 'submitted',
    Closed = 'closed'
}

// TODO: make this abstract.
export enum ConsultType {
    Optometrist = 'optometrist',
    Ophthalmologist = 'ophthalmologist'
}

export enum FormInputType {
    SingleRadio = 'singleRadio',
    Text = 'text',
    Textarea = 'textarea',
    Select = 'select',
    DatePicker = 'datePicker',
    DateOfBirth = 'dateOfBirth',
    ToggleGroup = 'toggleGroup',
    Time = 'time',
    Checkbox = 'checkbox',
    SingleCheckbox = 'singleCheckbox',
    RadioGroup = 'radioGroup',
    Upload = 'upload',
    DocUpload = 'docUpload',
    Visus = 'visus',
    ZdNumber = 'zdnumber',
    Refraction = 'refraction',
    SingleInputOptionalToggleGroup = 'singleInputOptionalToggleGroup',
    SingleInputArray = 'singleInputArray', // rename?
    OnlyLabels = 'onlyLabels', // rename?
    SpawnedMultiSelect = 'spawnedMultiSelect',
    Combined = 'combined',
    DateAndTime = 'dateAndTime',
    SelectFromDialog = 'selectFromDialog',
}

export enum FormType {
    Consult = 'consult'
}

export enum VecozoGender {
    Male = 'male',
    Female = 'female',
    Unknown = 'unknown',
    Unspecified = 'unspecified'
}

export enum VecozoInsuranceStatus {
    Active = 'active',
    Inactive = 'inactive',
    NotAuthorizedForHealthInsurer = 'unauthorized-by-health-insurer'
}

export enum VecozoInsuranceType {
    Additional = 'additional',
    AdditionalPlusDental = 'additional-plus-dental',
    AWBZ = 'awbz',
    Basic = 'basic',
    Main = 'main',
    Dental = 'dental'
}

export enum VecozoResultType {
    // In practice, only these should ever be encountered by VecozoService.getInsuranceData.
    Found = 'found',
    BsnInvalid = 'bsn-invalid',
    BsnUnknown = 'bsn-unknown',
    CombinationBsnAndBirthdateUnknown = 'combination-bsn-and-birthdate-unknown',

    // The rest should never happen.
    NotFound = 'not-found',
    InvalidSearchPath = 'invalid-path',
    FoundPollDateInFuture = 'found-poll-date-in-future',
    MultipleSearchPaths = 'multiple-search-paths',
    MultiplePersons = 'multiple-persons',
    Polldate2YearsOrOlder = 'poll-date-2-years-or-older'
}

export enum InvoiceStatus {
    Open = 'open',
    Paid = 'paid',
    Canceled = 'canceled',
    Refunded = 'refunded',
    Overdue = 'overdue'
}

// FIXME: make this dynamic.
export enum EquipmentType {
    Slitlamp = 'slitlamp',
    SlitlampCamera = 'slitlamp-camera',
    Tonometry = 'tonometry',
    FundusCamera = 'fundus-camera',
    OCT = 'oct',
    Perimeter = 'perimeter',
    AutoRefractor = 'auto-refractor',
    EndothelialCamera = 'endothelial-camera',
    Topographer = 'topographer',
    Pachymeter = 'pachymeter',
    Abberometer = 'abberometer',
    Biometrics = 'biometrics',
    Other = 'other'
}

export enum IDType {
    ID = 'id',
    Passport = 'passport',
    DriversLicense = 'drivers-license'
}

export enum TokenPurpose {
    Onboarding = 'onboarding',
}

export enum ConversationUpdateType {
    userAdded = 'userAdded',
    userRemoved = 'userRemoved',
    userLeft = 'userLeft',
}

export enum NotificationType {
    Conversation = 'conversation',
    ZdDocument = 'zd-document',
}

export enum FinalizeMethod {
    ZorgDomein = 'zorgdomein', // ingediend zd
    Letter = 'letter', // ingediend post
    Administrative = 'administrative' // finalized without actually submitting the dossier - used for administrative purposes.
}

export enum FinalizeZdNumberOptions {
    ZdnumberInvalid = 'zdnumber_invalid',
    ZdnumberCutOff = 'zdnumber_cutoff',
    ZdNumberOther = 'zdnumber_other'
}

export enum ResponsiblePartyType {
    User = 'user',
    Group = 'group'
}

export enum OrderType {
    StandardFlow = 'standard_flow', // used for standard flows (e.g. an examination protocol)
    ExtraExam = 'extra_exam', // used for extra examinations (e.g. an extra OCT scan),
    ExtraExamRequest = 'extra_exam_request', // used for extra examinations (e.g. an extra OCT scan),
    Appointment = 'appointment',
    Correspondence = 'correspondence',
    Other = 'other',
    Unknown = 'unknown' // used as default for newly created orders.
}

export enum OrderSubType {
    GVO = 'gvo',
    SlitLamp = 'slit_lamp',
    OCT = 'oct',
    Fundus = 'fundus',
    DarkAdaptation = 'dark_adaptation',
}

export enum SpecificExaminationType {
    Esterman = 'esterman',
    HFA30_2 = 'hfa30_2',
    HFA24_2 = 'hfa24_2',
    HFA10_2 = 'hfa10_2',
    Donders = 'donders',
}

export enum OrderStatus {
    Concept = 'concept',
    Open = 'open',
    Closed = 'closed',
    Cancelled = 'cancelled'
}

export enum ExternalCommunicationType {
    Email = 'email',
    Phone = 'phone',
    SMS = 'sms',
    Letter = 'letter',
    MEDVRI = 'medvri',
    MEDLAB = 'medlab',
    ZorgDomein = 'zorgdomein',
    ZorgMail = 'zorgmail'
}

export enum RecipientType {
    Patient = 'patient',
    HealthcareParty = 'healthcare-party',
    User = 'user',
}

export enum ExternalCommunicationStatus {
    Concept = 'concept',
    Sent = 'sent',
    Delivered = 'delivered',
    Error = 'error',
    Closed = 'closed'
}

export enum ExternalCommunicationRecordStatus {
    Draft = 'draft',
    Sent = 'sent',
    Delivered = 'delivered',
    Error = 'error'
}

// TODO: also make subtypes for GP and stuff?
export enum HealthcarePartyType {
    Individual = 'individual',
    Organization = 'organization',
    Branch = 'branch', // do not themselves deliver care, but are part of an organization that does.
    Unknown = 'unknown'
}

export enum ConfigEntryType {
    String = 'string',
    Number = 'number',
    Boolean = 'boolean',
    Object = 'object',
    Array = 'array'
}

export enum FhirZdDocumentOrganizationType {
    Sender = 'sender',
    Receiver = 'receiver',
}

export enum BSNOriginType {
    COV = 'cov',
    Manual = 'manual',
    None = 'none'
}

export enum ExamAction {
    Cancel = 'Annuleren',
    RequestExam = 'Aanvragen onderzoek',
    Refer = 'Verwijzen',
    Correspondence = 'Correspondentie',
    Edit = 'Bewerken',
    SaveAndSend = 'Opslaan en verstuur',
    SaveAsConcept = 'Opslaan als concept',
    Grade = 'Consult beoordelen',
    ReturnToOptometrist = 'Retour optometrist',
    Finalize = 'Afronden',
    ProcessReturned = 'Retour in behandeling nemen',
    SendToWorklist = 'DICOM-werklijst',
    FetchScans = 'Beelden ophalen',
    Continue = 'Doorgaan',
}

export enum ExamView {
    ReadOnly = 'readOnly',
    Edit = 'edit'
}
// @ts-ignore

export const SubjectClasses = [
    Appointment,
    Availability,
    AvailabilitySchedule,
    BillingInformation,
    CalendarEntry,
    Company,
    CompanyLocation,
    Equipment,
    ExaminationProtocol,
    ExaminationRoom,
    ExaminationType,
    Invoice,
    Patient,
    Payment,
    Schedule,
    User
] as const;

// Subjects type to be used by casl.
// Turn the const array into a union type: https://www.reddit.com/r/typescript/comments/nv0icn/is_it_possible_to_create_an_array_union_types_as/
// export type Subjects = InferSubjects<(typeof SubjectClasses)[number], true> | 'all';

// Turn the array into a map of modelName -> Type
export const subjectNameToClass: {readonly [modelName: string]: (typeof SubjectClasses)[number]} = SubjectClasses.reduce((prev, cur) => {
    prev[typeof cur] = cur;
    return prev;
}, {});

export enum Action {
    Manage = 'manage',
    Create = 'create',
    Read = 'read',
    Update = 'update',
    Delete = 'delete',
}

// These two are shorthands used throughout the application.
// export type AppAbilityTuple = [Action, Subjects];
// export type AppAbility = MongoAbility<AppAbilityTuple>;

