import {BaseFormInput, FormInputType, RemarksInput, SingleRadioInput} from "@util/types/interfaces";
import {YesNoDropdown} from "@util/types/dropdowns";

export enum SectionTitles {
    GeneralHealth = 'Jouw algemene gezondheid',
    EyeCorrection = 'Jouw oog correctie',
    EyeHealth = 'Jouw oog gezondheid',
    License = 'Jouw rijbewijs',
    ZDNumber = 'ZD Nummer',
    Complaint = 'Jouw oogklacht',
    Other = 'Overig'
}

// General Health
const isHealthy = new SingleRadioInput('Ben je gezond?', 'isHealthy', YesNoDropdown);
const healthRemarks = new RemarksInput('healthRemarks', {'form.isHealthy': 'no'});
const hasHadTreatmentForDiabetes = new SingleRadioInput('Ben je bekend met of in behandeling voor diabetes mellitus (suikerziekte)?', 'hasHadTreatmentForDiabetes', YesNoDropdown);
const hasHadTreatmentForDiabetesRemarks = new RemarksInput('hasHadTreatmentForDiabetesRemarks', {'form.hasHadTreatmentForDiabetes': 'yes'});
const hasHadTreatmentForStroke = new SingleRadioInput('Ben je bekend met of in behandeling voor een hersenbloeding/herseninfarct (CVA/TIA)?', 'hasHadTreatmentForStroke', YesNoDropdown);
const hasHadTreatmentForStrokeRemarks = new RemarksInput('hasHadTreatmentForStrokeRemarks', {'form.hasHadTreatmentForStroke': 'yes'});
const hasHadTreatmentForBrainTumor = new SingleRadioInput('Ben je bekend met of in behandeling voor een hersentumor?', 'hasHadTreatmentForBrainTumor', YesNoDropdown);
const hasHadTreatmentForBrainTumorRemarks = new RemarksInput('hasHadTreatmentForBrainTumorRemarks', {'form.hasHadTreatmentForBrainTumor': 'yes'});
const hasHadTreatmentForHypertension = new SingleRadioInput('Ben je bekend met of in behandeling voor hoge bloeddruk (hypertensie)?', 'hasHadTreatmentForHypertension', YesNoDropdown);
const hasHadTreatmentForHypertensionRemarks = new RemarksInput('hasHadTreatmentForHypertensionRemarks', {'form.hasHadTreatmentForHypertension': 'yes'});
const hasHadTreatmentForSleepApnea = new SingleRadioInput('Ben je bekend met of in behandeling voor slaapapneu?', 'hasHadTreatmentForSleepApnea', YesNoDropdown);
const hasHadTreatmentForSleepApneaRemarks = new RemarksInput('hasHadTreatmentForSleepApneaRemarks', {'form.hasHadTreatmentForSleepApnea': 'yes'});
const hasSmoked = new SingleRadioInput('Rook je of heb je in het verleden gerookt?', 'hasSmoked', YesNoDropdown);

// Eye Correction
const wearsGlasses = new SingleRadioInput('Draag je een bril?', 'wearsGlasses', YesNoDropdown);
const wearsReadingGlasses = new SingleRadioInput('Draag je een leesbril?', 'wearsReadingGlasses', YesNoDropdown, 'horizontal', null, {'form.wearsGlasses': 'yes'}, true);
const farSightedness = new SingleRadioInput('Draagt je een vertebril?', 'farSightedness', YesNoDropdown, 'horizontal', null, {'form.wearsGlasses': 'yes'}, true);
const multiFocalGlasses = new SingleRadioInput('Draagt je een multifocale bril?', 'multiFocalGlasses', YesNoDropdown, 'horizontal', null, {'form.wearsGlasses': 'yes'}, true);
const wearsGlassesRemarks = new RemarksInput('wearsGlassesRemarks', {'form.wearsGlasses': 'yes'}, true);

const wearsContactLenses = new SingleRadioInput('Draag je contactlenzen?', 'wearsContactLenses', YesNoDropdown);
const wearsSoftLenses = new SingleRadioInput('Draag je zachte lenzen?', 'wearsSoftLenses', YesNoDropdown, 'horizontal', null, {'form.wearsContactLenses': 'yes'}, true);
const wearsHardLenses = new SingleRadioInput('Draag je harde lenzen?', 'wearsHardLenses', YesNoDropdown, 'horizontal', null, {'form.wearsContactLenses': 'yes'}, true);
const wearsMultifocalLenses = new SingleRadioInput('Draag je multifocale lenzen?', 'wearsMultifocalLenses', YesNoDropdown, 'horizontal', null, {'form.wearsContactLenses': 'yes'}, true);

const wearsNightLenses = new SingleRadioInput('Gebruik je nachtlenzen?', 'wearsNightLenses', YesNoDropdown);
const wearsNightLensesRemarks = new RemarksInput('wearsNightLensesRemarks', {'form.wearsNightLenses': 'yes'});

// Eye Health
const hasHadLaserTreatment = new SingleRadioInput('Heb je ooit een laserbehandeling aan de ogen gehad?', 'hasHadLaserTreatment', YesNoDropdown);
const hasHadLaserTreatmentRemarks = new RemarksInput('hasHadLaserTreatmentRemarks', {'form.hasHadLaserTreatment': 'yes'});
const hasHadTreatmentForRetinalAbnormalities = new SingleRadioInput('Ben je bekend met of in behandeling voor netvliesafwijkingen ivm suikerziekte (diabetische retinopathie)?', 'hasHadTreatmentForRetinalAbnormalities', YesNoDropdown);
const hasHadTreatmentForRetinalAbnormalitiesRemarks = new RemarksInput('hasHadTreatmentForRetinalAbnormalitiesRemarks', {'form.hasHadTreatmentForRetinalAbnormalities': 'yes'});
const hasHadTreatmentForGlaucoma = new SingleRadioInput('Ben je bekend met of in behandeling voor glaucoom?', 'hasHadTreatmentForGlaucoma', YesNoDropdown);
const hasHadTreatmentForGlaucomaRemarks = new RemarksInput('hasHadTreatmentForGlaucomaRemarks', {'form.hasHadTreatmentForGlaucoma': 'yes'});
const hasHadTreatmentForCataract = new SingleRadioInput('Ben je bekend met of in behandeling voor staar (cataract)?', 'hasHadTreatmentForCataract', YesNoDropdown);
const hasHadTreatmentForCataractRemarks = new RemarksInput('hasHadTreatmentForCataractRemarks', {'form.hasHadTreatmentForCataract': 'yes'});
const hasHadTreatmentForMaculaDegeneration = new SingleRadioInput('Ben je bekend met of in behandeling voor macula degeneratie?', 'hasHadTreatmentForMaculaDegeneration', YesNoDropdown);
const hasHadTreatmentForMaculaDegenerationRemarks = new RemarksInput('hasHadTreatmentForMaculaDegenerationRemarks', {'form.hasHadTreatmentForMaculaDegeneration': 'yes'});
const hasHadTreatmentForLazyEye = new SingleRadioInput('Ben je bekend met of in behandeling voor een lui oog (amblyopie)?', 'hasHadTreatmentForLazyEye', YesNoDropdown);
const hasHadTreatmentForLazyEyeRemarks = new RemarksInput('hasHadTreatmentForLazyEyeRemarks', {'form.hasHadTreatmentForLazyEye': 'yes'});
const hasHadTreatmentForDryEyes = new SingleRadioInput('Ben je bekend met of in behandeling voor droge ogen?', 'hasHadTreatmentForDryEyes', YesNoDropdown);
const hasHadTreatmentForDryEyesRemarks = new RemarksInput('hasHadTreatmentForDryEyesRemarks', {'form.hasHadTreatmentForDryEyes': 'yes'});
const hasHadTreatmentForOtherReason = new SingleRadioInput('Ben je nu of ooit in het verleden onder behandeling geweest bij een oogarts voor een andere oorzaak dan bovenstaande?', 'hasHadTreatmentForOtherReason', YesNoDropdown);
const hasHadTreatmentForOtherReasonRemarks = new RemarksInput('hasHadTreatmentForOtherReasonRemarks', {'form.hasHadTreatmentForOtherReason': 'yes'});

// Other
export const additionalInformation = new BaseFormInput('Heb je nog aanvullende informatie?', 'additionalInformation', FormInputType.Textarea );

// Rijges
export const rijgesGeneralHealthQuestions = [
    isHealthy,
    healthRemarks,
    hasHadTreatmentForDiabetes,
    hasHadTreatmentForDiabetesRemarks,
    hasHadTreatmentForStroke,
    hasHadTreatmentForStrokeRemarks,
    hasHadTreatmentForBrainTumor,
    hasHadTreatmentForBrainTumorRemarks,
];

export const rijgesEyeCorrectionQuestions = [
    wearsGlasses,
    wearsContactLenses,
    wearsNightLenses,
];

export const rijgesEyeHealthQuestions = [
    hasHadTreatmentForDiabetes,
    hasHadTreatmentForDiabetesRemarks,
    hasHadTreatmentForGlaucoma,
    hasHadTreatmentForGlaucomaRemarks,
    hasHadTreatmentForCataract,
    hasHadTreatmentForCataractRemarks,
    hasHadTreatmentForMaculaDegeneration,
    hasHadTreatmentForMaculaDegenerationRemarks,
    hasHadTreatmentForLazyEye,
    hasHadTreatmentForLazyEyeRemarks,
    hasHadTreatmentForOtherReason,
    hasHadTreatmentForOtherReasonRemarks,
];


// Optometric Examination
export const optometricExaminationGeneralHealthQuestions = [
    isHealthy,
    healthRemarks,
    hasHadTreatmentForDiabetes,
    hasHadTreatmentForDiabetesRemarks,
    hasHadTreatmentForHypertension,
    hasHadTreatmentForHypertensionRemarks,
    hasHadTreatmentForStroke,
    hasHadTreatmentForStrokeRemarks,
    hasHadTreatmentForBrainTumor,
    hasHadTreatmentForBrainTumorRemarks,
    hasHadTreatmentForSleepApnea,
    hasHadTreatmentForSleepApneaRemarks,
    hasSmoked,
];

export const optometricExaminationCorrectionQuestions = [
    wearsGlasses,
    wearsReadingGlasses,
    farSightedness,
    multiFocalGlasses,
    wearsGlassesRemarks,
    wearsContactLenses,
    wearsSoftLenses,
    wearsHardLenses,
    wearsMultifocalLenses,
    wearsNightLenses,
    wearsNightLensesRemarks
];

export const optometricExaminationEyeHealthQuestions = [
    hasHadLaserTreatment,
    hasHadLaserTreatmentRemarks,
    hasHadTreatmentForRetinalAbnormalities,
    hasHadTreatmentForRetinalAbnormalitiesRemarks,
    hasHadTreatmentForGlaucoma,
    hasHadTreatmentForGlaucomaRemarks,
    hasHadTreatmentForCataract,
    hasHadTreatmentForCataractRemarks,
    hasHadTreatmentForMaculaDegeneration,
    hasHadTreatmentForMaculaDegenerationRemarks,
    hasHadTreatmentForLazyEye,
    hasHadTreatmentForLazyEyeRemarks,
    hasHadTreatmentForDryEyes,
    hasHadTreatmentForDryEyesRemarks,
    hasHadTreatmentForOtherReason,
    hasHadTreatmentForOtherReasonRemarks,
];
