import {Routes} from "@angular/router";
import {PatientInformationComponent} from "./patient-information.component";
import {MedicalInformationComponent} from "../medical-information/medical-information.component";
import {QuestionnairesComponent} from "../questionnaires/questionnaires.component";
import {environment} from "../../../environments/environment";

const tempProdRoutes: Routes = [{path: '', redirectTo: '/afspraak-maken', pathMatch: 'full'}];

export const PatientRoutes: Routes = environment.production ? tempProdRoutes : [
    {
        path: '',
        component: PatientInformationComponent,
    },
    {
        path: 'medische-gegevens',
        component: MedicalInformationComponent
    },
    {
        path: 'vragenlijsten',
        component: QuestionnairesComponent
    }
];
