<ng-container *ngIf="!popOverError else popoverError">
  <mat-error [class.hide]="_hide">
    <ng-container *ngFor="let _text of texts">
      <p>{{_text}}</p>
    </ng-container>
  </mat-error>
  <fa-icon class="errorIcon" [class.hide]="_hide" [icon]="faExclamationTriangle"></fa-icon>
</ng-container>

<ng-template #popoverError>
  <ng-container *ngIf="texts && texts.length > 0">
    <fa-icon
      *ngFor="let _text of texts"
      class="errorIcon popOverError"
      [class.hide]="_hide"
      [icon]="faExclamationTriangle"
      [matTooltip]="_text"
    ></fa-icon>
  </ng-container>
</ng-template>
