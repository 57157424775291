<div class="flex gap-8 flex-wrap lg:flex-nowrap" *ngIf="locationService.activeLocation">
    <app-step-card class="w-full lg:w-1/2">
        <ng-container header>
            <app-selected-location-card-header></app-selected-location-card-header>
        </ng-container>

        <ng-container body>
            <form class="flex flex-col gap-6" [formGroup]="infoFormGroup" (ngSubmit)="submit()">
                <ng-container *ngFor="let input of inputs">
                    <ng-container *ngIf="isArray(input) else singleInput">
                        <div class="grid grid-cols-1 gap-6" [ngClass]="getColumnLength(input.length)">
                            <app-form-inputs
                                    *ngFor="let actualInput of input"
                                    [input]="$any(actualInput)"
                                    [formGroup]="infoFormGroup"
                                    [showLabel]="actualInput.type !== FormInputType.SingleCheckbox"
                            ></app-form-inputs>
                        </div>
                    </ng-container>

                    <ng-template #singleInput>
                        <app-form-inputs
                            [input]=" $any(input)"
                            [formGroup]="infoFormGroup"
                            [showLabel]="$any(input).type !== FormInputType.SingleCheckbox"
                        ></app-form-inputs>
                    </ng-template>
                </ng-container>

                <button #hiddenButton hidden></button>
            </form>

            <mat-divider class="mt-2"></mat-divider>
        </ng-container>

        <ng-container footer>
            <app-step-card-footer
                [canProceed]="infoFormGroup.valid && infoFormGroup.get('dateOfBirth').value"
                (nextStep)="hiddenButton.click()"
            ></app-step-card-footer>

            <app-form-dev-tools
                [form]="infoFormGroup"
                [autoFillValues]="infoObj"
            ></app-form-dev-tools>
        </ng-container>
    </app-step-card>

    <app-appointment-summary
            class="w-full lg:w-1/2"
    ></app-appointment-summary>
</div>
