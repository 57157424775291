import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {TableColumn} from "@util/types/interfaces";
import {FormEntryService} from "@core/services/form-entry.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  public dynForm = null;

  constructor(
    private dynFormService: FormEntryService,
  ) {
    this.dynFormService.getAll().subscribe((dynForms) => {
      console.log(dynForms);
      this.dynForm = dynForms.items[0];
    });
  }
}
