<app-base-dialog
    [title]="'Form Entry'"
>
  <form (ngSubmit)="updateForm()" [formGroup]="formEntryGroup">
    <div class="formEntry py-4"
         *ngIf="formEntryGroup"
         cdkDropListGroup
         [formGroupName]="'data'"
    >
      <ng-container
        *ngFor="let section of getSections(); let sIndex = index; let last = last"
        [formArrayName]="'sections'"
      >
        <div
          class="formEntry__section flex rounded-xl"
          [formGroupName]="sIndex"
        >
          <div class="mr-4 flex flex-col min-w-32">
            {{section.get('label').value}}

            <div class="flex gap-4">
              <app-icon-button
                (onClick)="openFieldDialog(section)"
              >
                <fa-icon [icon]="['far', 'plus']"></fa-icon>
              </app-icon-button>

              <app-icon-button
                (onClick)="removeSection(section, sIndex)"
              >
                <fa-icon [icon]="['far', 'trash']"></fa-icon>
              </app-icon-button>
            </div>
          </div>

          <div
            class="example-list flex-1"
            cdkDropList
            (cdkDropListDropped)="drop($event, section.get('fields').value)"
            [cdkDropListData]="section.get('fields').value"
            [formGroupName]="'fields'"
            formErrorContainer
          >
            <div
              *ngFor="let field of section.get('fields').value; let fIndex = index"
              class="formEntry__field gap-4"
              cdkDrag
            >
              <div class="flex flex-col">
                <div class="font-bold">Label</div>
                <div>{{field.label}}</div>
              </div>

              <div class="flex flex-col">
                <div class="font-bold">Type</div>
                <div>{{enumValueToName(FormInputTypeDropdown, field.type)}}</div>
              </div>

              <fa-icon
                [icon]="['far', 'ellipsis-v']"
                class="ml-4 cursor-pointer"
                [matMenuTriggerFor]="fieldMenu"
              ></fa-icon>

              <mat-menu #fieldMenu="matMenu">
                <button mat-menu-item (click)="openFieldDialog(section, field, fIndex)">
                  <fa-icon [icon]="['far', 'edit']"></fa-icon>
                  <span class="ml-2">Bewerken</span>
                </button>
                <button mat-menu-item (click)="deleteField(section, field)">
                  <fa-icon [icon]="['far', 'trash']"></fa-icon>
                  <span class="ml-2">Verwijderen</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </div>

        <mat-divider class="my-4" *ngIf="!last"></mat-divider>
      </ng-container>
    </div>

    <button hidden #hiddenButton></button>
  </form>


  <app-icon-button
    (onClick)="addSection()"
  >
    <fa-icon [icon]="['far', 'plus']"></fa-icon>
  </app-icon-button>

  <div class="dialog__buttons flex gap-4" dialog-actions>
    <app-button (onClick)="dialogRef.close()">
      Annuleren
    </app-button>
    <app-button (onClick)="hiddenButton.click()">
      Opslaan
    </app-button>

    <app-form-dev-tools
      [form]="formEntryGroup"
    ></app-form-dev-tools>
  </div>
</app-base-dialog>
