<div class="sidebar h-screen w-16 p-4 flex flex-col">
  <img src="../../../../assets/Vorteq_V_yellow.svg">

  <ul class="nav flex flex-col gap-4 mt-8">
    <li
      *ngFor="let item of navItems"
      class="nav__item flex items-center justify-center"
      [routerLink]="item.uri ? item.uri : null"
      [routerLinkActive]="'nav__item--active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <fa-icon [icon]="item.icon"></fa-icon>
    </li>
  </ul>
</div>
