import { Component } from '@angular/core';
import {StepService} from "@core/services/step.service";
import {Router} from "@angular/router";
import {CompanyLocationService} from "@core/services/company-location.service";

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent {
  public steps = [
    {name: 'Behandeling'},
    {name: 'Datum & tijd'},
    {name: 'Gegevens invullen'},
    {name: 'Betaling'},
    {name: 'Afspraak bevestigen'}
  ];
  currentStep = this.stepService.currentStepIndex;
  isMakingAppointment = false;

  public links = [
    {label: 'Overzicht', url: '#'},
    {label: 'Consulten', url: '#'},
    {label: 'Medische gegevens', url: '/medische-gegevens'},
    {label: 'Vragenlijsten', url: '/vragenlijsten'},
    {label: 'Mijn profiel', url: '#'},
  ]

  constructor(
      private stepService: StepService,
      public locationService: CompanyLocationService,
      private router: Router
  ) {
    this.stepService.$currentStepIndex.subscribe((value) => {
      this.currentStep = value;
    });

    this.isMakingAppointment = this.router.url.includes('afspraak-maken');
    this.router.events.subscribe(() => {
      this.isMakingAppointment = this.router.url.includes('afspraak-maken');

      if (this.router.url.includes('bedankt')) {
        this.stepService.currentStepIndex = 5;
      }
    });
  }
}
