import {Component, Input} from '@angular/core';
import {BaseQuestionnaireTypeComponent, Section} from "../base-questionnaire-type/base-questionnaire-type.component";
import {QuestionnaireService} from "@core/services/questionnaire.service";
import { FormInputType } from '@util/types/interfaces';
import {FormArray, FormBuilder, FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-glaucoma-questionnaire',
  templateUrl: '../base-questionnaire-type/base-questionnaire-type.component.html',
  styleUrls: ['./glaucoma-questionnaire.component.scss']
})
export class GlaucomaQuestionnaireComponent extends BaseQuestionnaireTypeComponent {
  constructor(
      override questionnairesService: QuestionnaireService,
      override formBuilder: FormBuilder
  ) {
      const sections: Section[] = [
          {
              title: 'ZD Nummer',
              inputs: [
                  {label: 'Heeft u een ZD-nummer ontvangen?', key: 'hasReceivedZdNumber', type: FormInputType.SingleRadio, selectOptions: [{name: 'Nee', value: 'no'}, {name: 'Ja', value: 'yes'}]},
                  {label: 'Voer uw Zorgdomein (ZD) code in.', key: 'zdNumber', type: FormInputType.Text},
              ],
              hasErrors: false
          }
      ];

    super(questionnairesService, formBuilder, sections);
  }
}
