<button
  [class.mdc-button--secondary]="color === 'secondary'"
  mat-flat-button
  [type]="type"
  [disabled]="disabled"
  (click)="onClick.emit($event)"
>
  <ng-content></ng-content>
</button>

