<div class="flex flex-col lg:flex-row gap-8 relative">
    <div class="hidden lg:flex flex-col gap-6 min-w-64 sticky self-start top-4 text-deepsea-1">
        <a [routerLink]="'/vragenlijsten'">
            <fa-icon class="mr-2" [icon]="['far', 'arrow-left-long']"></fa-icon>
            Terug naar overzicht
        </a>
        <div
            *ngFor="let section of sections; let index = index"
            class="sideNav flex flex-col"
            [class.sideNav--active]="activeSection === section.title"
        >
            <div class="flex gap-2">
                <a href="{{'vragenlijsten#'+section.title}}">{{section.title}}</a>
                <div class="ml-auto">
                    <fa-icon *ngIf="section.hasErrors === false" class="text-success" [icon]="['fas', 'check-circle']"></fa-icon>
                    <fa-icon *ngIf="section.hasErrors" class="text-error" [icon]="['far', 'exclamation-triangle']"></fa-icon>
                </div>
            </div>
        </div>
    </div>

    <div class="flex flex-col lg:w-2/3">
        <h2 class="mb-8">{{title}}</h2>
        <form [formGroup]="questionnaireFormGroup" (ngSubmit)="save()" class="flex flex-col gap-6 flex-1">
            <section *ngFor="let section of sections" [id]="section.title" class="card p-6 flex flex-col">
                <div class="font-bold text-deepsea-3 mb-4">{{section.title}}</div>
                <div class="flex flex-col gap-6">
                    <ng-container *ngFor="let input of section.inputs">
                        <ng-container *ngIf="isArray(input) else singleInput">
                            <div class="flex gap-6">
                                <app-form-inputs
                                        class="w-full"
                                        [ngClass]="{'pl-4': input.isChild}"
                                        *ngFor="let actualInput of input"
                                        [input]="$any(actualInput)"
                                        [formGroup]="questionnaireFormGroup"
                                ></app-form-inputs>
                            </div>
                        </ng-container>

                        <ng-template #singleInput>
                            <div class="flex" *ngIf="input.visible">
                                <mat-divider vertical class="m-0 min-h-12 h-full pl-1 self-center" *ngIf="input.isChild"></mat-divider>
                                <app-form-inputs
                                        class="w-full"
                                        [ngClass]="{'pl-4': input.isChild}"
                                        [input]="$any(input)"
                                        [formGroup]="questionnaireFormGroup"
                                ></app-form-inputs>
                            </div>
                        </ng-template>
                    </ng-container>
                </div>
            </section>

            <button hidden #hiddenButton></button>
        </form>

        <div class="flex gap-6 mt-6">
            <app-button (click)="hiddenButton.click()">
                <fa-icon class="mr-2" [icon]="['far', 'save']"></fa-icon>
                Opslaan
            </app-button>
            <app-form-dev-tools [form]="questionnaireFormGroup"></app-form-dev-tools>
        </div>
    </div>
</div>
