<div class="tableWrapper">
  <table
    #tableElement
    [matSortActive]="selectedSort.active"
    [matSortDirection]="selectedSort.direction"
    (matSortChange)="sortData($event)"
    mat-table
    class="dataTable w-full mat-elevation-z0"
    matSort
    [dataSource]="dataSource"
  >
    <tr class="mat-row dataTable__row p-3 flex" *matNoDataRow>
      <td class="mat-cell dataTable__cell" colspan="9999">
        Geen {{noDataText}} gevonden.
      </td>
    </tr>

    <ng-container *ngFor="let column of tableColumns" [matColumnDef]="column.key">
      <ng-container [ngSwitch]="column.key">
        <ng-container *ngSwitchDefault>
          <ng-container *ngIf="column.sortable === true else textOnly">
            <th class="dataTable__header"
                mat-header-cell
                *matHeaderCellDef
                [mat-sort-header]="column.key"
            >
              {{column.headerText}}

              <fa-icon
                *ngIf="!selectedSort.active || selectedSort.active !== column.key"
                class="defaultSortIcon ms-2"
                [icon]="['far', 'sort']"
              ></fa-icon>

              <div class="wrap d-flex flex-column ms-2" *ngIf="selectedSort.active === column.key">
                <fa-icon
                  *ngIf="selectedSort.direction === 'asc'"
                  class="directionalIcon"
                  [icon]="['far', 'sort-up']"
                ></fa-icon>

                <fa-icon
                  *ngIf="selectedSort.direction === 'desc'"
                  class="directionalIcon"
                  [icon]="['far', 'sort-down']"
                ></fa-icon>
              </div>
            </th>
          </ng-container>
          <ng-template #textOnly>
            <th class="dataTable__header" mat-header-cell *matHeaderCellDef>
              {{column.headerText}}
            </th>
          </ng-template>

          <td class="dataTable__cell"
              mat-cell *matCellDef="let element"
              [class]="column.classes ? getClasses(column, element[column.key]) : ''"
          >
            {{
            column.parse ?
              parseData(resolveTableData(column.key, element), column.parse, element)
              : resolveTableData(column.key, element)
            }}

            <ng-container *ngIf="column.combine">
              {{resolveTableData(column.combine, element)}}
            </ng-container>
          </td>
        </ng-container>

<!--        <ng-container *ngSwitchCase="'status'">-->
<!--          <th class="dataTable__header" mat-header-cell *matHeaderCellDef="let element">-->
<!--            Status-->
<!--          </th>-->
<!--          <td class="dataTable__cell event" mat-cell *matCellDef="let element">-->
<!--            <ng-container [ngTemplateOutlet]="status" [ngTemplateOutletContext]="{ column, element }"></ng-container>-->
<!--          </td>-->
<!--        </ng-container>-->

        <ng-container *ngSwitchCase="'actions'">
          <th class="dataTable__header" mat-header-cell *matHeaderCellDef="let element">
            <button
              *ngIf="column.actions.headerActions && column.actions.headerActions.length > 1"
              class="button button--primary button--tableIcon"
              mat-button
              [matMenuTriggerFor]="headerMenu"
            >
              <fa-icon style="margin-right: 0;" [icon]="['far', 'add']"></fa-icon>
            </button>

            <button
              *ngIf="column.actions.headerActions && column.actions.headerActions.length === 1"
              class="button button--primary button--tableIcon"
              mat-button
              (click)="actionEvent.emit({eventType: column.actions.headerActions[0], entity: element})"
            >
              <fa-icon style="margin-right: 0;" [icon]="['far', 'add']"></fa-icon>
            </button>
          </th>

          <td class="dataTable__cell" mat-cell *matCellDef="let element">
            <button
              *ngIf="column.actions.rowActions"
              [matMenuTriggerData]="{element}"
              (click)="$event.stopPropagation()"
              class="button button--secondary button--tableIcon"
              mat-button [matMenuTriggerFor]="rowActions"
            >
              <fa-icon style="margin-right: 0;" [icon]="['far', 'ellipsis-v']"></fa-icon>
            </button>
          </td>

          <!--        // TODO: Dynamic-->
          <mat-menu class="custom-mat-menu" #headerMenu>
            <ng-template matMenuContent let-element="element">
              <button class="menuAction menuAction--add" mat-menu-item (click)="actionEvent.emit({eventType: 'add', entity: element})">
                <fa-icon class="me-2" [icon]="['far', 'add']"></fa-icon>
                Toevoegen
              </button>
            </ng-template>
          </mat-menu>
          <mat-menu class="custom-mat-menu" #rowActions>
            <ng-template matMenuContent let-element="element">
              <button class="menuAction menuAction--edit" mat-menu-item (click)="actionEvent.emit({eventType: 'download', entity: element})" *ngIf="column.actions.rowActions.includes(tableActions.Download)">
                <fa-icon class="me-2" [icon]="['far', 'download']"></fa-icon>
                Download
              </button>

              <button class="menuAction menuAction--edit" mat-menu-item (click)="actionEvent.emit({eventType: 'edit', entity: element})" *ngIf="column.actions.rowActions.includes(tableActions.Edit)">
                <fa-icon class="me-2" [icon]="['far', element.dataBefore || element.dataAfter ? 'history' : 'edit']"></fa-icon>  <!-- FIXME: TEMP FOR DEMO! -->
                {{element.dataBefore || element.dataAfter ? 'Herstel': 'Bewerken'}} <!-- FIXME: TEMP FOR DEMO! -->
              </button>

              <button class="menuAction menuAction--delete" mat-menu-item (click)="actionEvent.emit({eventType: 'delete', entity: element})" *ngIf="column.actions.rowActions.includes(tableActions.Delete)">
                <fa-icon class="me-2" [icon]="['far', 'trash']"></fa-icon>
                Verwijderen
              </button>
            </ng-template>
          </mat-menu>
        </ng-container>
      </ng-container>
    </ng-container>

    <tr mat-header-row class="dataTable__row dataTable__row--header" *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      class="dataTable__row"
      [class.dataTable__row--selected]="showSelectedRow ? selectedRow === row : undefined"
      *matRowDef="let row; let index = index; columns: displayedColumns;"
      (click)="this.rowIndex = index;"
    ></tr>
  </table>
</div>

<!--<app-paginator [paginationMeta]="paginationMeta" (paginationUpdate)="paginationChange.emit($event);"></app-paginator>-->

<!--<ng-template #status let-column="column" let-element="element">-->
<!--  <div class="event__status event__status&#45;&#45;small"-->
<!--       appStatusTag-->
<!--       [status]="element[column.key]"-->
<!--       [parseArray]="column.parse.array"-->
<!--  >-->
<!--    {{parseData(resolveTableData(column.key, element), column.parse, element)}}-->
<!--  </div>-->
<!--</ng-template>-->
