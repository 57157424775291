<app-step-card *ngIf="stepService.selectedExamType" class="w-full">
    <ng-container header>
        <span class="card__title">{{stepService.selectedExamType.prettyName}}</span>
    </ng-container>

    <p class="text-body card__paragraph">
        {{examTypeTextMap[stepService.selectedExamType.name] ?? 'Fout tijdens het ophalen van de tekst!'}}
    </p>

    <mat-divider class="my-10"></mat-divider>

    <ng-container body>
        <span class="card__title">Afspraakdetails</span>
        <div class="flex flex-col detail">
            <span class="detail__title">Locatie</span>
            <span>{{locationService.activeLocation.name}}</span>
            <span>{{parseAddress(locationService.activeLocation.address)}}</span>
        </div>

        <div class="flex flex-col detail">
            <span class="detail__title">Duur onderzoek</span>
            <span>{{stepService.selectedExamType.duration}} minuten</span>
        </div>

        <div class="flex flex-col detail">
            <span class="detail__title">Kosten</span>
            <span>€ {{stepService.selectedExamType.price}},-</span>
        </div>

        <div class="flex flex-col detail" *ngIf="appointmentService.selectedDate">
            <span class="detail__title">Datum</span>
            <span>{{appointmentService.selectedDate | amLocal | amDateFormat: 'dddd DD MMMM'}}</span>
        </div>

        <div class="flex flex-col detail" *ngIf="appointmentService.selectedTime">
            <span class="detail__title">Tijd</span>
            <span>{{appointmentService.selectedTime}}</span>
        </div>

        <ng-container *ngIf="appointmentService.clientInformation">
            <mat-divider *ngIf="true" class="my-4"></mat-divider>

            <span class="card__title">Uw gegevens</span>
            <div class="flex flex-col detail">
                <span class="detail__title">Initialen</span>
                <span>{{ appointmentService.clientInformation.initials }}</span>
            </div>

            <div class="flex flex-col detail">
                <span class="detail__title">Achternaam</span>
                <span>{{ appointmentService.clientInformation.lastName }}</span>
            </div>

            <div class="flex flex-col detail">
                <span class="detail__title">E-mail</span>
                <span>{{ appointmentService.clientInformation.email }}</span>
            </div>

            <div class="flex flex-col detail">
                <span class="detail__title">Telefoonnummer</span>
                <span>{{ appointmentService.clientInformation.phoneNumber }}</span>
            </div>

            <div class="flex flex-col detail">
                <span class="detail__title">Opmerkingen</span>
                <span>{{ appointmentService.clientInformation.remarks }}</span>
            </div>
        </ng-container>
    </ng-container>
</app-step-card>
