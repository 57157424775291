import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './base-button.component.html',
  styleUrls: ['./base-button.component.scss']
})
export class BaseButtonComponent {
  @Input() type: 'button' | 'submit' | 'reset' = 'button';
  @Input() color: 'primary' | 'secondary' = 'primary';
  @Input() disabled = false;
  @Input() label: string = 'Opslaan';
  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
}
