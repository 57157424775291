import {Component, Inject} from '@angular/core';
import {BaseDialogComponent} from "@components/organisms/dialogs/base-dialog/base-dialog.component";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FormInput, FormInputType, FormSelectInput} from "@util/types/interfaces";
import {FormInputTypeDropdown} from "@util/types/dropdowns";
import {HotToastService} from "@ngneat/hot-toast";
import {openToast, ToastType} from "@util/helpers/toastHelper";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {debounceTime} from "rxjs";

@UntilDestroy()
@Component({
  selector: 'app-form-entry-field-dialog',
  templateUrl: './form-entry-field-dialog.component.html',
  styleUrls: ['./form-entry-field-dialog.component.scss']
})
export class FormEntryFieldDialogComponent extends BaseDialogComponent {
  public override title = 'Dyn Form veld';
  public field = {
    label: '',
  };
  public formGroup: FormGroup = null;
  public inputs: FormSelectInput[] = [
    {label: 'Label', key: 'label', type: FormInputType.Text, required: true},
    // {label: 'Key', key: 'key', type: FormInputType.Text},
    {label: 'Type', key: 'type', type: FormInputType.Select, selectOptions: FormInputTypeDropdown, required: true},
    {label: 'Verplicht?', key: 'required', type: FormInputType.SingleCheckbox}, // TODO: Should be a select options with different validators?
  ];

  constructor(
    public override dialogRef: MatDialogRef<FormEntryFieldDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { field: any },
    private formBuilder: FormBuilder,
    private toastService: HotToastService
  ) {
    super(dialogRef);

    const controls = {};
    this.inputs.forEach((input: FormInput) => {
      controls[input.key] = ['', input.required ? [Validators.required] : []];
    });
    this.formGroup = this.formBuilder.group(controls);

    if (data.field) {
      this.field = data.field;
      this.formGroup.patchValue(data.field);
      if (this.hasMultipleOptions(data.field.type)) {
        this.formGroup.addControl('selectOptions', this.formBuilder.control(data.field.selectOptions, [Validators.required]));
      }
    }
    this.title = this.title += data.field ? ' aanpassen' : ' toevoegen';

    this.formGroup.get('label').valueChanges
      .pipe(untilDestroyed(this), debounceTime(500))
      .subscribe((value: string) => {
        this.formGroup.patchValue({key: value.toLowerCase().replace(/ /g, '_',)
        });
    });

    this.formGroup.get('type').valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: FormInputType) => {
      if (this.hasMultipleOptions(value)) {
        this.formGroup.addControl('selectOptions', this.formBuilder.control([], [Validators.minLength(1)]));
      } else {
        this.formGroup.removeControl('selectOptions');
      }
    });
  }

  updateField(): void {
    if (this.formGroup.invalid) {
      return;
    }

    this.dialogRef.close(this.formGroup);
  }

  addOption(inputEvent: Event): void {
    inputEvent.preventDefault();
    const targetValue = (inputEvent.target as any).value;
    const selectOptions = this.formGroup.get('selectOptions').value;
    const option = {
      name: targetValue,
      value: targetValue.toLowerCase().replace(/ /g, '_',)
    };

    const optionExists = selectOptions.find((selectOption: any) => selectOption.value === option.value);
    if (!optionExists) {
      selectOptions.push(option);
    } else {
      openToast(this.toastService, 'Deze optie bestaat al', ToastType.Warning);
      return;
    }

    (inputEvent.target as any).value = '';
  }

  removeOption(index: number): void {
    const selectOptions = this.formGroup.get('selectOptions').value;
    selectOptions.splice(index, 1);
  }

  hasMultipleOptions(type: FormInputType): boolean {
    return type === FormInputType.Select || type === FormInputType.SingleRadio;
  }

  protected readonly FormInputType = FormInputType;
}
