import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {FormGroup} from "@angular/forms";
import {TableActionType} from "@util/types/enums";
import {FormEntry, Form, User} from "@util/types/models";

export interface NavItem {
  uri: string;
  label: string;
  icon: IconProp;
  // active: boolean;
}

export interface DynamicFormManager {
  // component: BaseConsultTypeComponent,
  // data: {sections: ConsultFormSection[]}
  formGroup: FormGroup,
  // hasAnswers: boolean,
  // keys?: AnswerKeys[]
}

export enum DynamicFormView {
  ReadOnly = 'readOnly',
  Edit = 'edit'
}

export interface DynamicFormViewSubject {
  view: DynamicFormView,
  resetActions: boolean
}

export enum FormInputType {
  SingleRadio = 'singleRadio',
  SingleRadioNewline = 'singleRadioNewline',
  Text = 'text',
  Textarea = 'textarea',
  Select = 'select',
  DatePicker = 'datePicker',
  DateOfBirth = 'dateOfBirth',
  ToggleGroup = 'toggleGroup',
  Time = 'time',
  Checkbox = 'checkbox',
  SingleCheckbox = 'singleCheckbox',
  RadioGroup = 'radioGroup',
  Upload = 'upload',
  DocUpload = 'docUpload',
  DateAndTime = 'dateAndTime',
}

export interface FormInput {
  label?: string;
  // filterLabel?: string;
  placeholder?: string;
  type?: FormInputType | string; // FIXME: Some forms still have the type hardcoded, this should be changed to the enum.
  key?: string;
  required?: boolean;
  formGroupName?: string;
  visible?: boolean; // Hides the input
  disabled?: boolean; // Disables the input
  alternateStyling?: boolean; // Checkbox can have alternate styling
  // withBorder?: boolean; // FIXME
  children?: FormInput[]; // Use this to put the input fields in a row, instead of a column. Maybe rename this.
  actionButton?: { label: string, action: () => void };
  check?: { [key: string]: any };
  tooltip?: string;
  isChild?: boolean;
}

export interface FormSelectInput extends FormInput {
  selectOptions?: (DropdownData | DropdownDataWithDisabled | DropdownDataWithTags | DropdownDataWithTemplates)[];
  multi?: boolean;
  orientation?: 'horizontal' | 'vertical';
}

export class BaseFormInput implements FormInput {
  label: string;
  placeholder: string;
  type: FormInputType;
  key: string;
  check?: { [key: string]: any };
  tooltip?: string;
  isChild?: boolean;

  constructor(
      label: string,
      key: string,
      type: FormInputType,
      placeholder: string = '',
      check?: { [key: string]: any },
      tooltip?: string,
      isChild?: boolean
  ) {
    this.label = label;
    this.key = key;
    this.type = type;
    this.placeholder = placeholder;
    this.check = check;
    this.tooltip = tooltip;
    this.isChild = isChild;
  }
}

// TODO: Add more class based on types
export class SingleRadioInput extends BaseFormInput {
  selectOptions: DropdownData[];
  orientation: 'horizontal' | 'vertical';

  constructor(
      label: string,
      key: string,
      selectOptions: DropdownData[],
      orientation: 'horizontal' | 'vertical' = 'horizontal',
      tooltip?: string,
      check?: { [key: string]: any },
      isChild?: boolean
  ) {
    super(label, key, FormInputType.SingleRadio, '', check, tooltip, isChild);
    this.selectOptions = selectOptions;
    this.orientation = orientation;
  }
}

export class RemarksInput extends BaseFormInput {
  constructor(key: string, check: { [key: string]: any } = null, isChild = false, label: string = 'Toelichting') {
      super(label, key, FormInputType.Textarea, '', check, null, isChild);
  }
}

export interface DropdownData {
  name: string;
  value: any;

  pluralName?: string;
  icon?: IconProp;
  backgroundColor?: string;
  textColor?: string;
  borderColor?: string;
  alternateColors?: { backgroundColor: string, textColor: string, borderColor: string };
  action?: (any) => void;
}

export interface DropdownDataWithDisabled extends DropdownData {
  disabled?: boolean;
}

export interface DropdownDataWithTags extends DropdownData {
  tags: string[];
}

export interface DropdownDataWithTemplates extends DropdownData {
  templates: string[];
}

export class PaginatedResult<T> {
  items: T[];
  meta: IPaginationMeta;
  links?: IPaginationLinks
}

export interface IPaginationMeta {
  itemCount: number;
  totalItems: number;
  itemsPerPage: number;
  totalPages: number;
  currentPage: number;
}

export interface IPaginationLinks {
  first?: string;
  previous?: string;
  next?: string;
  last?: string;
}

export const DefaultPaginationValues = {
  PageSizeOptions: [10, 25, 50],
  PageSize: 10
}

export const DefaultPagination = {
  itemCount: 0,
  totalItems: 0,
  itemsPerPage: DefaultPaginationValues.PageSize,
  totalPages: 1,
  currentPage: 1
} as IPaginationMeta;

export interface TableColumn {
  headerText: string;
  key: string;
  sortable?: boolean;
  actions?: { headerActions?: TableActionType[], rowActions?: TableActionType[], noMenuActions?: string[] };
  classes?: string | ((column, value) => string); // Custom css classes
  // TODO: parse should be a function that returns a string (essentially how "func" is used), all these generic implementations should be utils that can be used in the parse function.
  parse?: { array?: DropdownData[], isDate?: boolean, dateFormat?: string, isName?: boolean, isImage?: boolean, isNameValue?: boolean, isDoB?: boolean, isFullName?: boolean, func?: (value: any) => string}; // TODO: ability to pass function?
  combine?: string; // Adds a value behind the first value
}


export interface TableActionEvent {
  eventType: string,
  entity: (Form | User | FormEntry)
}

export interface DropdownData {
  name: string;
  value: any;

  // pluralName?: string;
  // icon?: IconProp;
  // backgroundColor?: string;
  // textColor?: string;
  // borderColor?: string;
  // alternateColors?: { backgroundColor: string, textColor: string, borderColor: string };
  // action?: (any) => void;
}
