import {AfterViewInit, Component, ElementRef, QueryList, ViewChildren} from "@angular/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import { Router } from "@angular/router";

@Component({
    selector: 'app-otp',
    templateUrl: './otp.component.html',
    styleUrls: ['./otp.component.scss']
})

export class OtpComponent implements AfterViewInit {
    @ViewChildren('otpInput') otpInputs: QueryList<ElementRef<HTMLInputElement>>
    submitButton!: HTMLButtonElement;


    /** FIXME:: Hardcoded value. This should be generated via a back-end call. **/
    validCode: number[] = [1, 2, 3, 4, 5, 6];

    constructor(
        private snackBar: MatSnackBar,
        private router: Router,
    ) {}

    ngAfterViewInit(): void {
        this.submitButton = document.querySelector('button[type=submit]')!;
        this.otpInputs.forEach((input) => {
            input.nativeElement.addEventListener('input', this.handleInput.bind(this));
        })
    }

    /**
     * This function will handle when a user enters some input and refocus to the next input field.
     *
     * @param e
     */
    handleInput(e: Event): void {
        const target = e.target as HTMLInputElement;
        const inputs = this.otpInputs.toArray().map(input => input.nativeElement);
        const index = inputs.indexOf(target);
        if (target.value) {
            if (index < inputs.length - 1) {
                inputs[index + 1].focus();
            } else {
                this.submitButton.focus();
            }
        }
    }

    /**
     * This function will handle the form submission.
     */
    onSubmit(): void {
        const otp = this.otpInputs.toArray().map(input => input.nativeElement.value);
        if (otp.join('') === this.validCode.join('')) {
            this.snackBar.open('Verificatie geslaagd, u wordt doorverwezen.', 'Sluiten', {duration: 5000})
            // Redirect to patients.
            this.router.navigateByUrl('/patient');
        } else {
            this.snackBar.open('Verificatiecode is onjuist!', 'Sluiten', {duration: 5000})
        }
    }

    /**
     * Handle the resend button click.
     */
    onResend(): void {
        this.snackBar.open('Nieuwe verificatiecode is verzonden.', 'Sluiten', {duration: 5000})
    }
}
