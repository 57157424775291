import {Component, OnDestroy} from '@angular/core';
import {BaseStepComponent} from "../base-step/base-step.component";
import {StepService} from "@core/services/step.service";
import {AppointmentService} from "@core/services/appointment.service";
import {CompanyLocationService} from "@core/services/company-location.service";
import {PaymentService} from "@core/services/payment.service";

export enum PaymentStatus {
  open = "open",
  canceled = "canceled",
  pending = "pending",
  authorized = "authorized",
  expired = "expired",
  failed = "failed",
  paid = "paid"
}

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent extends BaseStepComponent implements OnDestroy {
  public paymentId: string = null;
  public payment = null;
  public messageToDisplay = 'Wachten op betaling...';
  public payInStore = false;

  constructor(
      override stepService: StepService,
      private appointmentService: AppointmentService,
      private locationService: CompanyLocationService,
      private paymentService: PaymentService,
  ) {
    super(stepService);

    // TODO: Set everything in appointmentService, instead of spreading it out over multiple services
    const appointment = JSON.parse(localStorage.getItem('appointment'));
    this.appointmentService.appointmentDTO = appointment;
    this.appointmentService.clientInformation = appointment.patient;
    this.stepService.selectedExamType = appointment.examinationProtocol;
    this.locationService.activeLocation = appointment.location;
    this.paymentId = localStorage.getItem('paymentId');

    if (this.paymentId) {
      this.paymentService.checkPayment(this.appointmentService.appointmentDTO.id).subscribe(response => {
        if (response) {
          this.payment = response;
          this.checkPayment();
        } else {
          const interval = setInterval(() => { // TODO why do we need this is we have websockets?
            this.paymentService.checkPayment(this.appointmentService.appointmentDTO.id).subscribe(response => {
              this.payment = response;
              this.checkPayment(interval);
            });
          }, 5000);
        }
      });
    } else {
      this.payInStore = true;
      // this.router.navigate(['/boeking']);
    }

    window.onbeforeunload = () => this.ngOnDestroy();
  }

  getStatus(statuses = []) {
    return statuses.indexOf(this.payment?.status) > -1 || this.payInStore;
  }

  paymentWait() {
    return [PaymentStatus.open, PaymentStatus.pending].indexOf(this.payment?.status) > -1;
  }

  // TODO: ngOnDestroy(), remove localStorage items
  ngOnDestroy() {
      localStorage.removeItem('paymentId');
      localStorage.removeItem('appointment');
  }

  private checkPayment(interval?): void {
    switch(this.payment.status) {
      case PaymentStatus.open:
        this.messageToDisplay = 'Wachten op betaling...';
        break;
      case PaymentStatus.paid:
        this.messageToDisplay = 'Bedankt voor uw boeking bij Okulus!';
        if (interval) {
          clearInterval(interval);
        }

        this.createAppointment(interval);
        break;
      case PaymentStatus.authorized:
        this.messageToDisplay = 'Wachten op betaling...';
        break;
      case PaymentStatus.failed:
        this.messageToDisplay = 'De betaling is mislut.';
        break;
      case PaymentStatus.canceled:
        this.messageToDisplay = 'U heeft de betaling afgebroken.';
        break;
      case PaymentStatus.pending:
        this.messageToDisplay = 'Wachten op betaling...';
        break;
      case PaymentStatus.expired:
        this.messageToDisplay = 'De betaling is verlopen.';
        break;
    }
  }

  private createAppointment(interval): void {
    // FIXME: should be postcode API!
    this.appointmentService.appointmentDTO.patient.address = {
      ...this.appointmentService.appointmentDTO.patient.address,
      city: '',
      streetName: ''
    }
    this.appointmentService.complete(this.appointmentService.appointmentDTO.id, this.appointmentService.appointmentDTO).subscribe(data => {
      interval ? clearInterval(interval) : null;
    });
  }
}
