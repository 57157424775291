import {NgModule} from "@angular/core";
import {PatientInformationComponent} from "./patient-information.component";
import {NgForOf, NgIf} from "@angular/common";
import {MomentModule} from "ngx-moment";
import {RouterModule} from "@angular/router";
import {PatientRoutes} from "./patient.routes";

@NgModule({
    declarations: [
        PatientInformationComponent
    ],
    imports: [
        NgForOf,
        NgIf,
        MomentModule,
        RouterModule.forChild(PatientRoutes)
    ],
    exports: [
        PatientInformationComponent
    ]
})
export class PatientInformationModule {
}
