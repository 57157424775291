import {Component, Inject} from '@angular/core';
import {FormService} from "@core/services/form.service";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {MatTableDataSource} from "@angular/material/table";
import {Form} from "@util/types/models";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-form-dialog',
  templateUrl: './form-dialog.component.html',
  styleUrls: ['./form-dialog.component.scss']
})
export class FormDialogComponent {
  public title = 'Formulier';
  public formGroup: FormGroup;

  constructor(
    private formService: FormService,
    @Inject(MAT_DIALOG_DATA) public data: { form: Form },
    private formBuilder: FormBuilder,
  ) {

  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      identifier: ['', [Validators.required]],
      name: ['', [Validators.required]],
      type: ['', [Validators.required]],
    });

    this.formService.get(this.data.form.id).subscribe((form) => {
      this.title = form.name;
      this.formGroup.patchValue(form);
    });
  }
}
