<app-base-dialog
  [title]="title"
>
  <form (ngSubmit)="updateField()" class="field">
    <app-form-inputs
      *ngFor="let input of inputs"
      [input]="input"
      [formGroup]="formGroup"
    ></app-form-inputs>

    <div class="flex flex-col mt-4" *ngIf="formGroup.get('selectOptions')">
      <div>Opties</div>
      <mat-hint>Vul een optie in en druk op enter.</mat-hint>

      <mat-form-field>
        <input
          matInput
          (keydown.enter)="addOption($event)"
        />
      </mat-form-field>

      <mat-chip-set class="w-50">
        <mat-chip
          *ngFor="let option of formGroup.get('selectOptions').value; let i = index"
          [removable]="true"
          (removed)="removeOption(i)"
        >
          {{ option.name }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-set>
    </div>

    <button #hiddenSubmit type="submit" hidden></button>
  </form>

  <div class="dialog__buttons flex gap-4" dialog-actions>
    <app-button>
      Annuleren
    </app-button>
    <app-button (onClick)="hiddenSubmit.click()">
      Opslaan
    </app-button>

    <app-form-dev-tools
      [form]="formGroup"
    />
  </div>
</app-base-dialog>
