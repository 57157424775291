<app-base-page>
    <div class="w-1/2 mx-auto">
        <h1 class="text-3xl mb-8 font-bold text-deepsea-3">
            Vragenlijsten
        </h1>

        <app-form-inputs
            [input]="questionnaireTypeInput"
            [formGroup]="typeForm"
        ></app-form-inputs>
    </div>

    <div class="flex gap-8">
        <ng-container *ngIf="selectedQuestionnaire">
            <div class="flex flex-col">
                <ng-container *ngComponentOutlet="
                    selectedQuestionnaire.component;
                    inputs: selectedQuestionnaire.inputs;
                "/>
            </div>
        </ng-container>
    </div>
</app-base-page>
