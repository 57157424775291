import {Component, inject, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {StepService} from "@core/services/step.service";

@Component({
  selector: 'app-base-step',
  templateUrl: './base-step.component.html',
  styleUrls: ['./base-step.component.scss']
})
export class BaseStepComponent {
  @Input() title: string;
  public infoFormGroup: FormGroup;
  public infoObj = {
    "initials": "J.",
    "lastName": "de Tester",
    "gender": "male",
    "email": "jan@vorteq.nl",
    "emailRepeat": "jan@vorteq.nl",
    "phoneNumber": "0612345678",
    "remarks": "",
    "agree": false,
    "address": {
      "postalCode": "3044ck",
      "houseNr": "62"
    }
  }

  constructor(
      protected stepService: StepService,
  ) {
  }
}
