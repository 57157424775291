<div
  formErrorContainer
  [questionKey]="input.key"
  class="errorWrapper radioWrapper"
  [formGroup]="input.formGroupName ? $any(formGroup.get(input.formGroupName)) : formGroup"
>
  <mat-radio-group
    [formControlName]="input.key"
    class="flex gap-8"
    [class.flex-col]="input.orientation === 'vertical'"
  >
    <mat-radio-button *ngFor="let option of input.selectOptions" [value]="option.value">
      {{ option.name }}
    </mat-radio-button>
  </mat-radio-group>
</div>
