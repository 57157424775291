import {Component, Inject} from '@angular/core';
import {BaseDialogComponent} from "@components/organisms/dialogs/base-dialog/base-dialog.component";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FormEntry, FormEntryStatus, FormEntryType} from "@util/types/models";
import {FormEntryService} from "@core/services/form-entry.service";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {
  FormEntryFieldDialogComponent
} from "@components/organisms/dialogs/form-entry-field-dialog/form-entry-field-dialog.component";
import {enumValueToName} from "@util/types/enums";
import {FormInputTypeDropdown} from "@util/types/dropdowns";
import {openToast, ToastType} from "@util/helpers/toastHelper";
import {HotToastService} from "@ngneat/hot-toast";
import {
  ConfirmationDialogComponent
} from "@components/organisms/dialogs/confirmation-dialog/confirmation-dialog.component";
import {Observable} from "rxjs";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {minArrayLength} from "@util/validators/minArrayLength";

@Component({
  selector: 'app-form-entry-dialog',
  templateUrl: './form-entry-dialog.component.html',
  styleUrls: ['./form-entry-dialog.component.scss']
})
export class FormEntryDialogComponent extends BaseDialogComponent {
  public formEntryGroup: FormGroup;

  constructor(
    public override dialogRef: MatDialogRef<FormEntryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { formEntry: FormEntry },
    private formEntryService: FormEntryService,
    private dialog: MatDialog,
    private toastService: HotToastService,
    private formBuilder: FormBuilder,
  ) {
    super(
        dialogRef
    );
  }

  override ngOnInit() {
    this.formEntryGroup = this.formBuilder.group<Partial<FormEntry>>({
      status: FormEntryStatus.Open,
      type: FormEntryType.Example,
      data: this.formBuilder.group({
        sections: this.formBuilder.array([], Validators.minLength(1)),
      }),
    });

    if (this.data.formEntry) {
      this.data.formEntry.data.sections.forEach(s => {
        this.addSection(s);
      })

      this.formEntryGroup.patchValue(this.data.formEntry);
    } else {
      const section = this.formEntryGroup.get('data').get('sections') as FormArray;
      section.push(this.formBuilder.group({
        label: ['Nieuwe sectie', [Validators.required]],
        fields: this.formBuilder.array([], [minArrayLength(1)]),
      }))
    }
  }

  drop(event: CdkDragDrop<string[]>, array: any) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  updateForm() {
    console.log(this.formEntryGroup)
    if (this.formEntryGroup.invalid) {
      return;
    }

    const observable = this.data.formEntry ? this.formEntryService.update(this.data.formEntry.id, this.formEntryGroup.value) : this.formEntryService.create(this.formEntryGroup.value);
    observable.subscribe((dynForm) => {
      if (dynForm) {
        this.dialogRef.close(dynForm);
        openToast(this.toastService, 'Formulier opgeslagen!', ToastType.Success);
      }
    });
  }

  openFieldDialog(section, field?, fieldIndex?) {
    this.dialog.open(FormEntryFieldDialogComponent, {
      data: {
        field: field || null,
      }
    }).afterClosed().subscribe((updatedField: FormGroup) => {
      if (updatedField) {
        if (!field) {
          section.get('fields').push(updatedField);
          return;
        }

        section.get('fields').at(fieldIndex).patchValue(updatedField.value);
      }
    });
  }

  deleteField(section, field) {
    this.openConfirmationDialog('Weet je zeker dat je dit veld wilt verwijderen?').subscribe((result) => {
      if (result) {
        section.fields = section.fields.filter(f => f !== field);
      }
    });
  }

  getSections() {
    const sections = this.formEntryGroup.get('data').get('sections') as FormArray;
    return sections.controls;
  }

  addSection(sectionData?): void {
    const newSection = this.formBuilder.group({
      label: ['Nieuwe sectie', [Validators.required]],
      fields: this.formBuilder.array([], [minArrayLength(1)]),
    });

    const sections = this.formEntryGroup.get('data').get('sections') as FormArray;
    sections.push(newSection);

    if (!sectionData) {
      return;
    }

    sectionData.fields.forEach(f => {
      this.addField(newSection);
    });
  }

  addField(section) {
    const newField = this.formBuilder.group({
      label: ['', [Validators.required]],
      type: ['', [Validators.required]],
      selectOptions: [[]]
    });

    const fields = section.get('fields') as FormArray;
    fields.push(newField);
  }

  removeSection(section, index) {
    this.openConfirmationDialog('Weet je zeker dat je deze sectie wilt verwijderen?').subscribe((result) => {
      if (result) {
        const sections = this.formEntryGroup.get('data').get('sections') as FormArray;
        sections.removeAt(index);
      }
    });
  }

  openConfirmationDialog(message: string): Observable<any> {
    return this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: message
      }
    }).afterClosed();
  }

  protected readonly enumValueToName = enumValueToName;
  protected readonly FormInputTypeDropdown = FormInputTypeDropdown;
}
