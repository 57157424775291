import {Injectable, Optional} from "@angular/core";
// import {EventTypes, OidcSecurityService, PublicEventsService} from "angular-auth-oidc-client";
// import {LoginResponse} from "angular-auth-oidc-client/lib/login/login-response";
import {firstValueFrom, Observable, Subscription} from "rxjs";
import {filter, map} from "rxjs/operators";
import {environment} from "../../../environments/environment";

@Injectable()
export class SecurityService {
  // TODO: userId is used as principalUserId... this is not correct.
  public userId: string;
  public principalUserId: string;
  public accessToken: string;

  public get devOnly(): boolean {
    return environment.skipAuthentication;
  }

  constructor(
    // @Optional() public oidcSecurityService?: OidcSecurityService,
    // @Optional() private eventService?: PublicEventsService
  ) {
    // if(eventService) {
    //   this.eventService.registerForEvents()
    //     //.pipe(filter((notification) => notification.type === EventTypes.NewAuthenticationResult))
    //     .subscribe((value) => {
    //       console.log(value);
    //     });
    // }

    if(this.devOnly) {
      if(localStorage.getItem('userId')) {
        this.userId = localStorage.getItem('userId');
      }
    }

    // else {
    //   this.oidcSecurityService.getAccessToken().subscribe(token => {
    //     this.accessToken = token;
    //   });
    // }
  }

  getAccessToken(): string {
    if (!this.devOnly) {
      if(!this.accessToken) {
        console.error("No access token available (yet)!");
        return null;
      }

      return this.accessToken;
    } else {
      throw new Error("Attempting to get access token while not in production mode");
    }
  }

  setup(ssoToken?: string): Observable<void> {
    if (!this.devOnly) {
      // return this.oidcSecurityService.checkAuth().pipe(map(((auth: LoginResponse) => {
      //   console.log(auth);
      //
      //   if (!auth.isAuthenticated) {
      //     this.authorize(ssoToken);
      //   }
      //
      //   if(auth.errorMessage) {
      //     console.error(auth.errorMessage);
      //   }
      //
      //   if(auth.accessToken) {
      //     this.accessToken = auth.accessToken;
      //   }
      //
      //   return auth;
      // })));
    }

    return new Observable<void>();
  }

  authorize(ssoToken?: string): void {
    if (!this.devOnly) {
      // this.oidcSecurityService.authorize(null, {
      //   urlHandler(url: string): any {
      //     if(ssoToken) {
      //       console.log("SSO token found... logging in user...");
      //       url += '&user_exchange_token=' + ssoToken;
      //     }
      //     window.location.replace(url);
      //   }
      // });
    }
  }

  logoff(): void {
    if (!this.devOnly) {
      // this.oidcSecurityService.logoff();
    }
  }

  // TODO: move this to an interceptor.
  getHttpRequestOptions(): object {
    if (this.devOnly) {
      if (this.userId) {
        return {
          headers: {
            'debug-principal-user-id': this.userId
          }
        };
      } else {
        return {}
      }
    }

    return {}
  }
}
