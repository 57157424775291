import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FaIconLibrary} from "@fortawesome/angular-fontawesome";
import {
  faArrowLeftLong,
  faArrowRight,
  faBolt, faCheck, faChevronLeft, faChevronRight,
  faCoffee,
  faDownload,
  faEdit,
  faEllipsisV,
  faExclamationTriangle,
  faHome, faLockKeyhole, faPlus, faPrint,
  faTrash
} from "@fortawesome/pro-regular-svg-icons";
import {faCode} from "@fortawesome/pro-regular-svg-icons/faCode";
import {faTimes} from "@fortawesome/pro-regular-svg-icons/faTimes";
import {faCheckCircle} from "@fortawesome/pro-solid-svg-icons";



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class FontAwesomeLibraryModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faCoffee,
      faHome,
      faCode,
      faTimes,
      faBolt,
      faEdit,
      faEllipsisV,
      faDownload,
      faTrash,
      faExclamationTriangle,
      faPlus,
      faCheck,
      faArrowRight,
      faCheckCircle,
      faArrowLeftLong,
      faChevronLeft,
      faChevronRight,
      faPrint,
      faLockKeyhole
    );
  }
}
