import {DropdownData, FormInputType} from "@util/types/interfaces";
import {ExamType} from "@util/types/models/_template";

export const FormInputTypeDropdown: DropdownData[] = [
  {value: FormInputType.Text, name: 'Tekst'},
  {value: FormInputType.Select, name: 'Selecteer'},
  {value: FormInputType.Textarea, name: 'Tekstveld'},
  {value: FormInputType.SingleCheckbox, name: 'Selectievakje'},
  {value: FormInputType.SingleRadio, name: 'Keuzerondje'},
  {value: FormInputType.DatePicker, name: 'Datum'},
];

export const ExamTypeDropdown: DropdownData[] = [
  {name: 'Fundusscreening', value: ExamType.FundusScreening},
  {name: 'Rijbewijskeuring 107', value: ExamType.Rijges107},
  {name: 'Rijbewijskeuring 095', value: ExamType.Rijges095},
  {name: 'Rijbewijskeuring 104', value: ExamType.Rijges104},
  {name: 'Optometrisch onderzoek', value: ExamType.OptometricExamination},
  {name: 'Glaucoomscreening', value: ExamType.GlaucomaScreening},
  {name: 'Brilmeting', value: ExamType.GlassesMeasurement},
  {name: 'Orthoptisch onderzoek', value: ExamType.OrthopticExamination},
  {name: 'Droge ogen', value: ExamType.DryEyes},
]

export const YesNoDropdown: DropdownData[] = [
  {name: 'Nee', value: 'no'},
    {name: 'Ja', value: 'yes'},
];
