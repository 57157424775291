import { Component } from '@angular/core';
import {NavItem} from "@util/types/interfaces";



@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  navItems: NavItem[] = [
    {uri: '/', label: 'Dashboard', icon: ['far', 'house']},
    {uri: 'form-entries', label: 'Dynamische Formulieren', icon: ['far', 'bolt']},
    {uri: 'forms', label: 'Formulieren', icon: ['far', 'edit']},
  ];
}
