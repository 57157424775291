<ng-container
  [formGroup]="input.formGroupName ? $any(formGroup.get(input.formGroupName)) : formGroup"
>
  <div
    #errorElement
    [class.hasError]="hasError"
  >
    <ng-select
      [items]="input.selectOptions"
      bindLabel="name"
      bindValue="value"
      [placeholder]="input.placeholder"
      [clearable]="false"
      [closeOnSelect]="!input.multi"
      [searchable]="searchable"
      [multiple]="input.multi"
      [formControlName]="input.key"
      notFoundText="Geen opties gevonden."
      appendTo="body"
      formErrorContainer
      [questionKey]="input.key"
    >
      <ng-template ng-label-tmp let-item="item">
        <div class="selectedValue" *ngIf="item.value">
          {{item.name}}
        </div>
      </ng-template>

      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
        <div class="selectedValue" *ngFor="let item of items | slice:0:1">
          {{$any(item).name}}
          <span class="ms-2" (click)="clear(item)" aria-hidden="true">×</span>
        </div>
        <div class="selectedValue" *ngIf="items.length > 1">
          <span>+ {{items.length - 1}}</span>
        </div>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <div
          class="optionWrapper"
          [class.optionWrapper--noBorder]="input.multi"
          [class.optionWrapper--hasAddress]="item.address"
        >
          <label class="d-flex checkBoxContainer align-items-center w-100">
            <div class="checkmark me-2" *ngIf="input.multi">
              <fa-icon *ngIf="item$.selected" [icon]="['far', 'circle-check']"></fa-icon>
              <fa-icon *ngIf="!item$.selected" [icon]="['far', 'circle']"></fa-icon>
            </div>
            <span>{{item.name}}</span>

            <div class="optionWrapper__tags d-flex align-items-center gap-2" *ngIf="item.tags">
<!--              <mat-divider vertical class="optionWrapper__divider"></mat-divider>-->
              <div class="selectedValue" *ngFor="let tag of item.tags">
                {{tag}}
              </div>
            </div>

            <div class="optionWrapper__address d-flex align-items-center" *ngIf="item.address">
<!--              <mat-divider vertical class="optionWrapper__divider"></mat-divider>-->
              {{item.address}}
            </div>
            <div class="optionWrapper__distance ms-auto" *ngIf="item.distance">{{item.distance}}</div>
          </label>
        </div>
      </ng-template>
    </ng-select>

<!--    <fa-icon-->
<!--      *ngIf="isDisabled()"-->
<!--      [icon]="['far', 'lock-keyhole']"-->
<!--      class="disabledIcon"-->
<!--      [class.disabledIcon&#45;&#45;searchable]="searchable"-->
<!--    ></fa-icon>-->
  </div>

  <div class="mat-mdc-form-field-subscript-wrapper mat-mdc-form-field-bottom-align" ng-reflect-ng-switch="hint">
      <div class="mat-mdc-form-field-hint-wrapper ng-tns-c1205077789-5 ng-trigger ng-trigger-transitionMessages ng-star-inserted" style="opacity: 1; transform: translateY(0%);">
        <div class="mat-mdc-form-field-hint-spacer ng-tns-c1205077789-5"></div>
    </div>
  </div>
</ng-container>
