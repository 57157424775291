import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {DashboardComponent} from "./pages/dashboard/dashboard.component";
import {PatientInformationComponent} from "./pages/patient-information/patient-information.component";
import {CreateAppointmentComponent} from "./pages/create-appointment/create-appointment.component";
import {ConfirmationModule} from "./pages/create-appointment/steps/confirmation/confirmation.module";
import {ConfirmationComponent} from "./pages/create-appointment/steps/confirmation/confirmation.component";
import {environment} from "../environments/environment";
import {QuestionnairesComponent} from "./pages/questionnaires/questionnaires.component";

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard'
      }
    ]
  },
  {
    path: 'form-entries',
    loadChildren: () => import('./pages/form-entries/form-entries.module').then(m => m.FormEntriesModule)
  },
  {
    path: 'forms',
    loadChildren: () => import('./pages/forms/forms.module').then(m => m.FormsModule)
  },
  {
    path: 'patient',
    loadChildren: () => import('./pages/patient-information/patient-information.module').then(m => m.PatientInformationModule)
  },
  {
    path: 'afspraak-maken',
    component: CreateAppointmentComponent
  },
  {
    path: 'afspraak-maken/bedankt',
    component: ConfirmationComponent
  },
  {
    path: 'vragenlijsten',
    component: QuestionnairesComponent
  }
];

const tempProdRoutes: Routes = [
  {
    path: '',
    redirectTo: '/afspraak-maken',
    pathMatch: 'full'
  },
  {
    path: 'afspraak-maken',
    component: CreateAppointmentComponent
  },
  {
    path: 'afspraak-maken/bedankt',
    component: ConfirmationComponent
  },
  {
    path: 'vragenlijsten',
    component: QuestionnairesComponent
  }
];


@NgModule({
  imports: [RouterModule.forRoot(environment.production ? tempProdRoutes : routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
