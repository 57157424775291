import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
  DynamicFormManager,
  DynamicFormView,
  DynamicFormViewSubject,
  FormInput,
  FormInputType, FormSelectInput
} from "@util/types/interfaces";
import {FormBuilder, Validators} from "@angular/forms";
import {debounceTime, distinctUntilChanged, Subject, Subscription} from "rxjs";
import {FormEntryService} from "@core/services/form-entry.service";
import {FormEntryDialogComponent} from "@components/organisms/dialogs/form-entry-dialog/form-entry-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {FormSection} from "@util/types/models";

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit, OnDestroy {
  @Input() dynForm: any;
  public managers: DynamicFormManager[] = [];
  public managersWithAnswers: DynamicFormManager[] = [];
  public currentManager: DynamicFormManager;
  public currentManagerIndex = 0;
  public currentView: DynamicFormView = DynamicFormView.ReadOnly;
  public currentViewSubject: Subject<DynamicFormViewSubject> = new Subject<DynamicFormViewSubject>();
  public AUTOSAVE_DEBOUNCE = 5000;
  public autoSaved: Date = null;
  private autoSaveSub: Subscription;

  public sections: FormSection[] = [];
  public inputs: FormInput[] | FormSelectInput[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private dynFormService: FormEntryService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    const controls = {};

    this.sections = this.dynForm.data.sections;
    this.sections.forEach(section => {
      section.fields.forEach(field => {
        if (field.children) {
          field.children.forEach(subInput => {
            controls[subInput.key] = [null, (subInput as any).required ? Validators.required : null];
          });
          return;
        }

        controls[field.key] = [null, (field as any).required ? Validators.required : null];
      });
    })


    this.currentManager = {
      formGroup: this.formBuilder.group(controls)
    };

    // setupForms();
  }

  ngOnDestroy(): void {
    if (this.autoSaveSub) {
      this.autoSaveSub.unsubscribe();
    }
  }

  public async setupForms(): Promise<void> {
    this.currentManagerIndex = 0; // Reset in case of addExam
    this.managersWithAnswers = []; // Reset in case of addExam

    // this.managers = this.exam.consults.map((c, consultIndex) => {
    //   const component = new BaseConsultTypeComponent(this.examService, this.formBuilder);
    //   component.consultData = c;
    //   component.examData = this.exam;
    //
    //   const data = component.getData(this.exam.examinationProtocol.examinationType, consultIndex);
    //
    //   // FIXME; rename consult key
    //   return {
    //     component,
    //     data,
    //     consult: c,
    //     formGroup: this.formBuilder.group({}),
    //     hasAnswers: false,
    //     keys: []
    //   }
    // });

    // this.currentManager = this.managers.find(m => m.consult.id === this.exam.actionRequiredForId);
    // if(!this.currentManager) {
    //   console.warn("No actionRequiredFor found, using last consult!");
    //   this.currentManager = this.managers[this.managers.length - 1];
    // }
    this.setNext(this.currentManagerIndex);

    // Use a for loop instead of forEach to make sure the order is correct.
    // When using forEach, sometimes the data is patched in the wrong order.
    for (let i = 0; i < this.managers.length; i++) {
      const manager = this.managers[i];
      // const data = manager.consult.data ? manager.consult.data : {};
      // const hasAnyData = data ? this.hasTruthyValue(data) : false;

      // if (hasAnyData) {
        // manager.formGroup.patchValue(data);

        // if (i !== this.managers.length -1 && this.managers[i + 1].consult.parentId) {
        //   this.managersWithAnswers.push(manager);
        //   this.currentManagerIndex = this.currentManagerIndex + 1;
        //   this.setNext(this.currentManagerIndex);
        // }
      // }
    }

    setTimeout(() => {
      this.autoSaveSub = this.currentManager?.formGroup?.valueChanges.pipe(
        distinctUntilChanged(),
        debounceTime(this.AUTOSAVE_DEBOUNCE)
      ).subscribe((value) => {
        this.autoSaved = new Date();
      });
    }, 500)
  }

  setNext(index: number): void {
    // const manager = this.managers[index];
    // this.hasAccessToCurrentForm = this.checkIfCanAccess();
    // const keys = manager.component.keys;
    // this.setFormKeys(consult.data.sections[0].questions, keys, null, consult);
  }

  save() {
    if (!this.currentManager.formGroup.valid) {
      return;
    }

    console.log('Form saved');
  }

  getColumns(length: number): string {
    if (length === 2) {
      return 'w-1/2';
    } else if (length === 3) {
      return 'w-1/3';
    } else if (length === 4) {
      return 'w-1/4';
    } else if (length === 5) {
      return 'w-1/5';
    }

    return 'w-full';
  }

  isArray(input: any): boolean {
    return Array.isArray(input);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(FormEntryDialogComponent, {});
  }
}
