import { Component } from '@angular/core';
import {BaseQuestionnaireTypeComponent, Section} from "../base-questionnaire-type/base-questionnaire-type.component";
import {QuestionnaireService} from "@core/services/questionnaire.service";
import {FormBuilder} from "@angular/forms";
import {BaseFormInput, FormInputType, RemarksInput, SingleRadioInput} from "@util/types/interfaces";
import {YesNoDropdown} from "@util/types/dropdowns";
import {
  additionalInformation,
  rijgesEyeCorrectionQuestions, rijgesEyeHealthQuestions,
  rijgesGeneralHealthQuestions,
  SectionTitles
} from "../sharedQuestions";

@Component({
  selector: 'app-rijges107-questionnaire',
  templateUrl: '../base-questionnaire-type/base-questionnaire-type.component.html',
  styleUrls: ['./rijges107-questionnaire.component.scss']
})
export class Rijges107QuestionnaireComponent extends BaseQuestionnaireTypeComponent {
  constructor(
      override questionnairesService: QuestionnaireService,
      override formBuilder: FormBuilder
  ) {
    // TODO: get values from backend / admin-frontend.
    const sections: Section[] = [
      {
        title: SectionTitles.ZDNumber,
        inputs: [
          new BaseFormInput('Voer uw Zorgdomein (ZD) code in.', 'zdNumber', FormInputType.Text, '', null, 'Toelichting: De Zorgdomein (ZD) code staat op de brief die u van het CBR heeft ontvangen.'),
        ]
      },
      {
        title: SectionTitles.License,
        inputs: [
          new SingleRadioInput('Ben je in het bezit van een Nederlands rijbewijs?', 'hasLicense', YesNoDropdown),
          new SingleRadioInput('Beschik je over een groep 2 rijbewijs?', 'has_group_2_license', YesNoDropdown, 'horizontal', 'Toelichting: Groep 2 rijbewijs is voor de volgende categorieën;\n' +
              '- categorie C (vrachtwagen)\n' +
              '- categorie D (bus)\n' +
              '- categorie CE/DE (aanhanger bij respectievelijk vrachtwagen en bus).'),
          new BaseFormInput('Tot wanneer is je rijbewijs geldig?', 'license_date', FormInputType.DatePicker),
        ]
      },
      {
        title: SectionTitles.GeneralHealth,
        inputs: rijgesGeneralHealthQuestions
      },
      {
        title: SectionTitles.EyeCorrection,
        inputs: rijgesEyeCorrectionQuestions,
      },
      {
        title: SectionTitles.EyeHealth,
        inputs: [
          ...rijgesEyeHealthQuestions,
          new SingleRadioInput('Heb je last van dubbelbeelden?', 'doubleVision', YesNoDropdown),
          {label: 'Gebruik je hiervoor een hulpmiddel (bij prisma in de bril)?', key: 'doubleVisionAid', type: FormInputType.SingleRadio, selectOptions: YesNoDropdown, isChild: true, check: {'form.doubleVision': 'yes'}},
          {label: 'Zijn de dubbelbeelden hinderlijk?', key: 'doubleVisionBothersome', type: FormInputType.SingleRadio, selectOptions: YesNoDropdown, isChild: true, check: {'form.doubleVision': 'yes'}},
        ]
      },
      {
        title: SectionTitles.Other,
        inputs: [additionalInformation]
      }
    ]

    super(questionnairesService, formBuilder, sections);
  }
}
