import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseQuestionnaireTypeComponent } from './base-questionnaire-type.component';
import {BaseButtonModule} from "@components/atoms/buttons/base-button/base-button.module";
import {FormInputsModule} from "@components/molecules/forms/form-inputs/form-inputs.module";
import {ReactiveFormsModule} from "@angular/forms";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {FormErrorModule} from "@util/directives/form-error-directive/form-error.module";
import {FormDevToolsModule} from "@components/molecules/forms/form-dev-tools/form-dev-tools.module";
import {GlaucomaQuestionnaireComponent} from "../glaucoma-questionnaire/glaucoma-questionnaire.component";
import {Rijges107QuestionnaireComponent} from "../rijges107-questionnaire/rijges107-questionnaire.component";
import {Rijges095QuestionnaireComponent} from "../rijges095-questionnaire/rijges095-questionnaire.component";
import {RouterLink} from "@angular/router";
import {MatListModule} from "@angular/material/list";
import {
    OptometricExaminationQuestionnaireComponent
} from "../optometric-examination-questionnaire/optometric-examination-questionnaire.component";



@NgModule({
    declarations: [
        BaseQuestionnaireTypeComponent,
        GlaucomaQuestionnaireComponent,
        Rijges107QuestionnaireComponent,
        Rijges095QuestionnaireComponent,
        OptometricExaminationQuestionnaireComponent
    ],
    exports: [
        BaseQuestionnaireTypeComponent
    ],
    imports: [
        CommonModule,
        BaseButtonModule,
        FormInputsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        FormErrorModule,
        FormDevToolsModule,
        RouterLink,
        MatListModule
    ]
})
export class BaseQuestionnaireTypeModule { }
