import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import {DynamicFormModule} from "@components/templates/dynamic-form/dynamic-form.module";
import {DataTableModule} from "@components/organisms/data-table/data-table.module";
import {BasePageModule} from "../base-page/base-page.module";
import {OtpModule} from "@components/templates/otp/otp.module";
import {PatientInformationModule} from "../patient-information/patient-information.module";



@NgModule({
  declarations: [
    DashboardComponent
  ],
    imports: [
        CommonModule,
        DynamicFormModule,
        DataTableModule,
        BasePageModule,
        OtpModule,
        PatientInformationModule
    ]
})
export class DashboardModule { }
