import {Component, inject} from '@angular/core';
import {ExamTypeDropdown} from "@util/types/dropdowns";
import {FormInput, FormInputType} from "@util/types/models";
import {FormBuilder} from "@angular/forms";
import {StepService} from "@core/services/step.service";
import {QuestionnaireService} from "@core/services/questionnaire.service";
import {ExamType} from "@util/types/models/_template";

@Component({
  selector: 'app-questionnaires',
  templateUrl: './questionnaires.component.html',
  styleUrls: ['./questionnaires.component.scss']
})
export class QuestionnairesComponent {
    private questionnaireList = inject(QuestionnaireService).getQuestionnaires();
    public selectedQuestionnaire = null;
     public questionnaireTypeInput: FormInput = {
        type: FormInputType.Select,
        label: 'Selecteer een vragenlijst',
        key: 'type',
        selectOptions: ExamTypeDropdown
     };
     public typeForm = null;

     constructor(private formBuilder: FormBuilder) {
        this.typeForm = this.formBuilder.group({
         type: [ExamType.OptometricExamination]
        });

         this.selectedQuestionnaire = this.questionnaireList.find((questionnaire) => questionnaire.examType === this.typeForm.value.type);
        this.typeForm.valueChanges.subscribe((value) => {
            this.selectedQuestionnaire = this.questionnaireList.find((questionnaire) => questionnaire.examType === value.type);
        });
     }
}
