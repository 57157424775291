import {Component, OnInit} from '@angular/core';
import {FormInput, FormInputType, FormSelectInput} from "@util/types/interfaces";
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-medical-information',
  templateUrl: './medical-information.component.html',
  styleUrls: ['./medical-information.component.scss']
})
export class MedicalInformationComponent implements OnInit {
  public activeSection = 0;
  protected readonly FormInputType = FormInputType;
  public medicalFormGroup: FormGroup;

  constructor(private formBuilder: FormBuilder) {}


  ngOnInit() {

  }
}
