<div class="flex gap-8 flex-wrap lg:flex-nowrap">
    <app-step-card class="w-full lg:w-1/2">
        <ng-container header>
            <app-selected-location-card-header></app-selected-location-card-header>
        </ng-container>

        <ng-container body>
            <app-treatment-item
                *ngFor="let treatment of locationService.examinationTypes"
                [treatment]="treatment"
                [selectedTreatment]="selectedTreatment"
                (selectTreatment)="selectTreatment($event)"
            ></app-treatment-item>
        </ng-container>

        <ng-container footer>
            <app-step-card-footer
                [canProceed]="!!selectedTreatment"
            ></app-step-card-footer>
        </ng-container>
    </app-step-card>

    <app-appointment-summary
        *ngIf="selectedTreatment"
        class="w-full lg:w-1/2"
    ></app-appointment-summary>

    <div *ngIf="!selectedTreatment" class="w-1/2"></div>
</div>
